define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
  'modules/shop.cash-register-retail/components/backgroudSync',
  'modules/upx/components/upx',

  'modules/shop.cash-register-retail/components/onlineFoodOrder',
], (
  $, _, Backbone, Component, BGSync, Upx,
  OnlineFoodOrder,
) => {
  const PaymentAttachmentComponent = Component.extend({

    JOB_ATTACH_PAYMENTS_TO_ORDER: 'payment::attach_order_payments',
    JOB_ATTACH_PAYMENTS_TO_INVOICE: 'payment::attach_invoice_payments',

    initialize(option) {
      Component.prototype.initialize.apply(this, option);

      BGSync.registerJobType(this.JOB_ATTACH_PAYMENTS_TO_ORDER, this.attachPaymentsToOrder, this);
      BGSync.registerJobType(this.JOB_ATTACH_PAYMENTS_TO_INVOICE, this.attachPaymentsToInvoice, this);
    },

    getPaymentBackRef(paymentId) {
      return `PaymentModule::Payment(id=${paymentId})`;
    },

    getOrderBackRef(orderId) {
      return `ShopModule::Order(id=${orderId})`;
    },

    getInvoiceBackRef(invoiceId) {
      return `BillingModule::Invoice(id=${invoiceId})`;
    },

    scheduleAndAwaitAttachPaymentsToOrder({
      paymentIds,
      orderId,
    }) {
      const backrefs = [this.getOrderBackRef(orderId)]
        .concat(paymentIds.map((id) => this.getPaymentBackRef(id)));

      return BGSync.addAndAwaitJob(
        this.JOB_ATTACH_PAYMENTS_TO_ORDER,
        backrefs,
        {
          paymentIds,
          orderId,
        },
      );
    },

    scheduleAttachPaymentsToOrder({
      paymentIds,
      orderId,
    }) {
      const backrefs = [this.getOrderBackRef(orderId)]
        .concat(paymentIds.map((id) => this.getPaymentBackRef(id)));

      return BGSync.addJob(
        this.JOB_ATTACH_PAYMENTS_TO_ORDER,
        backrefs,
        {
          paymentIds,
          orderId,
        },
      );
    },

    async attachPaymentsToOrder({
      paymentIds,
      orderId,
    }) {
      await BGSync.awaitDeferred(Upx.call(
        'ShopModule',
        'attachPaymentIdsToOrder',
        {
          fields: {
            payment_ids: paymentIds,
          },
          id: orderId,
        },
      ));

      OnlineFoodOrder.syncByShopOrderId(orderId);
    },

    scheduleAndAwaitAttachPaymentsToInvoice({
      paymentIds,
      invoiceId,
    }) {
      const backrefs = [this.getInvoiceBackRef(invoiceId)]
        .concat(paymentIds.map((id) => this.getPaymentBackRef(id)));

      return BGSync.addAndAwaitJob(
        this.JOB_ATTACH_PAYMENTS_TO_INVOICE,
        backrefs,
        {
          paymentIds,
          invoiceId,
        },
      );
    },

    scheduleAttachPaymentsToInvoice({
      paymentIds,
      invoiceId,
    }) {
      const backrefs = [this.getInvoiceBackRef(invoiceId)]
        .concat(paymentIds.map((id) => this.getPaymentBackRef(id)));

      return BGSync.addJob(
        this.JOB_ATTACH_PAYMENTS_TO_INVOICE,
        backrefs,
        {
          paymentIds,
          invoiceId,
        },
      );
    },

    attachPaymentsToInvoice({
      paymentIds,
      invoiceId,
    }) {
      return BGSync.awaitDeferred(Upx.call(
        'BillingModule',
        'attachPaymentIdsToInvoice',
        {
          fields: {
            payment_ids: paymentIds,
          },
          id: invoiceId,
        },
      ));
    },

  });

  return new PaymentAttachmentComponent();
});
