define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
  'modules/shop.cash-register-retail/components/backgroudSync',

  'upx.modules/PaymentModule/models/Payment',
  'upx.modules/PaymentModule/models/GiftCardPayment',
  'upx.modules/ShopModule/models/OrderInvoice',
  'upx.modules/ShopModule/models/LoyaltyProgramPointsPayment',

  'modules/shop.cash-register-retail/collections/paymentResults',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/customerScreenData.js',

  'modules/shop.cash-register-retail/collections/upx/PaymentProviderMethod',
  'modules/shop.cash-register-retail/collections/upx/PaymentProvider',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/common/components/connection',
  'modules/shop.cash-register-retail/models/paymentMethodItem',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/common/components/promisify',
  'modules/upx/components/upx',
  'modules/common/components/uuid',

  'modules/shop.cash-register-retail/views/popups/paymentErrorPopup',
  'modules/shop.cash-register-retail/components/onlineFoodOrder',
  'modules/shop.cash-register-retail/components/paymentMethod.js',
  'qrcode',
  'modules/shop.cash-register-retail/components/receiptSigning.js',

  'modules/shop.cash-register-retail/models/ccv/ccvPin',
  'modules/shop.cash-register-retail/models/ccv/ccvPaymentHandler',
  'modules/shop.cash-register-retail/models/ccv/openCCVPinTransaction',

  'modules/shop.cash-register-retail/components/paymentAttachment',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/giftCardPaymentProvider',
], (
  $, _, Backbone, Component, BGSync,
  PaymentModel, GiftCardPaymentModel, OrderInvoiceModel, LoyaltyProgramPointsPaymentModel,
  PaymentResultsCollection,
  DefaultShopConfigurationModel,
  ReceiptPrinterModel, TerminalSetting, CustomerScreenData,
  PaymentProviderMethodCollection, PaymentProviderCollection, CashRegisterApi,
  ConnectionComponent, PaymentMethodItemModel, PaymentMethodsSettingModel,
  Currency, Locale, Promisify, Upx, Uuid,
  PaymentErrorPopup, OnlineFoodOrder, PaymentMethod, Qrcode, ReceiptSigning,
  CCVPin, CCVPaymentHandler, OpenCCVPinTransaction,
  PaymentAttachment, MessagePopupView, PaymentMethods,
  DeviceConfig, GiftCardPaymentProvider,
) => {
  // const QR_STATUS_CODE_INIT = 'INIT'; // for consistency
  const QR_STATUS_CODE_SCANNED = 'SCANNED';
  const QR_STATUS_CODE_CONFIRMED = 'CONFIRMED';
  const QR_STATUS_CODE_VERIFY = 'VERIFY';
  const QR_STATUS_CODE_PAID = 'PAID';
  const QR_STATUS_CODE_CANCELLED = 'CANCELLED';
  const QR_STATUS_CODE_EXPIRED = 'EXPIRED';

  const PaymentComponent = Component.extend({

    async getOrderSynchronizingPaymentIds(orderId) {
      const jobs = await BGSync.getJobsForObject(PaymentAttachment.getOrderBackRef(orderId));
      const paymentIds = [];
      jobs.forEach((job) => {
        job.backRefs.forEach((backref) => {
          const match = backref.match(/^PaymentModule::Payment\(id=(\d+)\)/);
          if (match) {
            paymentIds.push(match[1]);
          }
        });
      });
      return paymentIds;
    },

    hasNonEmptyPayment(id, paymentMethodCollection, isRefund = false) {
      let nonEmptyPayment = false;
      paymentMethodCollection.each((paymentMethodItem) => {
        const modelId = paymentMethodItem.get('id');

        const isValid = isRefund ? paymentMethodItem.get('refund_amount') !== '0.00' : paymentMethodItem.get('ppu_wt') !== '0.00';
        if (modelId.startsWith(id) && isValid) {
          nonEmptyPayment = true;
        }
      });
      return nonEmptyPayment;
    },

    getPaymentResultByMethod(paymentsResult, method) {
      return paymentsResult.find((model) => {
        const paymentMethod = model.get('paymentMethod');

        return paymentMethod && paymentMethod.id === method;
      });
    },

    refundPayments(results) {
      const payments = results.getRefundable();
      const def = new $.Deferred();

      const calls = [];
      const non_refunded_ids = [];
      const refundIds = [];

      for (let i = 0; i < payments.length; i++) {
        const payment = payments.at(i);
        const paymentId = payment.get('payment_id');
        calls.push(Upx.prepareCall(
          () => Upx.call('PaymentModule', 'refundPayment', { id: paymentId }),
          // success
          (refundId) => {
            payment.set('refunded', true);
            payment.set('refundId', refundId);
            refundIds.push(refundId);
          },
          // on error
          (error) => {
            non_refunded_ids.push(paymentId);
            payment.set('refundError', error);
          },
        ));
      }

      Upx.eachCall(calls, false)
        .then(
          () => {
            if (non_refunded_ids.length === 0) {
              // all refunded
              def.resolve({ refundIds });
            } else {
              // When it failed during refunding.
              def.reject({
                error: Locale.translate('the_order_creation_failed_and_the_refunding_process_also_failed_please_contact_storekeeper_support_dot'),
                non_refunded_ids,
                refundIds,
              });
            }
          },
          // Rejects when there is an JS error.
          (error) => def.reject({
            error,
            refundIds,
          }),
        );
      return def;
    },

    processExternalGiftCardPayment({
      results,
      paymentMethodCollection,
      processingView,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.EXTERNAL_GIFTCARD_METHOD;
      if (this.hasNonEmptyPayment(methodAlias, paymentMethodCollection)) {
        const payments = paymentMethodCollection.filterByNonemptyMethod(methodAlias);
        const paymentsCalls = [];

        for (let i = 0; i < payments.length; i++) {
          const model = payments[i];

          const process = () => {
            const paymentDef = new $.Deferred();
            const log = processingView.payment(methodAlias, model, null, true);

            log.setProcessingStatus();
            const getPaymentParameters = () => ({
              fields: {
                amount: model.get('ppu_wt'),
                code: model.get('code'),
                pin: model.get('pin'),
                provider_id: model.get('provider_id') || PaymentProviderCollection.getUpxPayProviderId(),
                pos_uuid: DeviceConfig.getDeviceUUID() || null,
              },
            });

            const processSuccess = (paymentId) => {
              log.hidePinInput();
              log.success();
              results.addSuccessful(payments[i], paymentId, true);
              paymentDef.resolve(paymentId);
            };

            const processError = (paymentResponse) => {
              // it`s ok sot store cos we stop on first error - Szymon
              const error = Locale.translate(
                'failed_process_gift_card_{0}_with_the_following_reason_{1}',
                [model.get('code'), paymentResponse.error],
              );

              log.error(error);
              results.addError(model, error);

              if (
                paymentResponse.class === 'PaymentModule::InvalidPinCode'
                  || paymentResponse.class === 'PaymentModule::PaymentProvider'
              ) {
                log.showPinInput();
              } else {
                paymentDef.reject();
              }
            };

            log.on(
              'payment:cancel',
              (btnDef) => {
                const message = Locale.translate('payment_canceled');
                log.error(message);
                btnDef.resolve();
                def.reject({ error: message });
              },
            );

            log.on(
              'payment:submit:pin',
              (btnDef, pin) => {
                log.hidePinInput();
                log.setProcessingStatus();
                model.set('pin', pin);
                Upx.call('PaymentModule', 'ensureExternalGiftCardPayment', getPaymentParameters()).then(
                  (paymentId) => {
                    btnDef.resolve();
                    processSuccess(paymentId);
                  },
                  processError,
                );
              },
            );

            Upx.call('PaymentModule', 'ensureExternalGiftCardPayment', getPaymentParameters()).then(
              processSuccess,
              processError,
            );

            return paymentDef.promise();
          };

          paymentsCalls.push(process);
        }

        const paymentIds = [];
        const processAllPaymentCalls = (processCalls) => {
          const eachCallDef = new $.Deferred();

          if (processCalls.length > 0) {
            const processCall = processCalls.shift();
            const next = () => {
              processAllPaymentCalls(processCalls).then(
                eachCallDef.resolve,
                eachCallDef.reject,
              );
            };

            $.when(processCall()).then((paymentId) => {
              paymentIds.push(paymentId);
              next();
            });
          } else {
            eachCallDef.resolve();
          }

          return eachCallDef.promise();
        };

        processAllPaymentCalls(paymentsCalls).then(() => {
          def.resolve(paymentIds);
        }, def.reject);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    pinTerminalIsSelected() {
      return !!TerminalSetting.has('provider_id') && !!TerminalSetting.has('id');
    },

    getAmountOrRest(model, toBePaidValue, totalPaymentValue) {
      let restAmount = model.get('ppu_wt');

      if (restAmount === '0.00' || restAmount === 0) {
        // calculate the rest amount
        if (parseFloat(totalPaymentValue) < parseFloat(toBePaidValue)) {
          // the rest can only be positive
          restAmount = Currency.Math.subtract(toBePaidValue, totalPaymentValue);
        }
      }
      return restAmount;
    },

    getPaymentProducts(orderItemCollection, subtractDiscount = false) {
      const products = [];
      orderItemCollection.each((model) => {
        let ppu_wt = model.get('ppu_wt');
        if (model.has('discount_ppu_wt') && subtractDiscount) {
          // discount_ppu_wt is internal POS representation for discount
          ppu_wt -= model.get('discount_ppu_wt');
        }
        products.push(
          {
            sku: model.get('sku'),
            name: model.get('name'),
            ppu_wt,
            tax: model.get('tax'),
            quantity: model.get('quantity'),
          },
        );
        const subitems = model.get('sub_items') || model.get('subitems') || [];
        subitems.forEach((item) => {
          products.push(
            {
              sku: item.sku,
              name: item.name,
              ppu_wt: item.ppu_wt,
              tax: item.tax,
              quantity: item.quantity,
            },
          );
        });
      });
      return products;
    },

    processPaymentMethods({
      totalValueWt,
      processingView,
      cashierDisplay,
      orderModel = null,
      invoiceModel = null,
      paymentMethodCollection,
      paymentProducts,
      relation_data_id,
    }) {
      const def = new $.Deferred();

      if (
        ReceiptPrinterModel.canOpenDrawer()
        && (
          paymentMethodCollection.get(paymentMethodCollection.CASH_METHOD)
          || this.hasSpareChange(paymentMethodCollection)
        )
      ) {
        const log = processingView.drawerOpen();
        ReceiptPrinterModel.openDrawer()
          .then(
            () => log.success(),
            (error) => log.error(error),
          );
      }

      const processError = (error) => {
        processingView.error(error);
        return def.reject({ error });
      };

      if (
        paymentMethodCollection.get(paymentMethodCollection.PAYLATER_METHOD)
        && !orderModel
      ) {
        return processError('Pay later is not possible without an order');
      }

      if (
        paymentMethodCollection.get(paymentMethodCollection.INVOICE_METHOD)
        && !orderModel
      ) {
        return processError('Invoice is not possible without an order');
      }

      const results = new PaymentResultsCollection();
      const paymentCalls = [
        () => this.processExternalGiftCardPayment({
          results,
          paymentMethodCollection,
          processingView,
        }),
        () => this.processQrPayment({
          results,
          paymentMethodCollection,
          processingView,
          totalValueWt,
          paymentProducts,
          relation_data_id,
        }),
        () => this.processPinPayment({
          results,
          paymentMethodCollection,
          processingView,
          cashierDisplay,
          totalValueWt,
          orderModel,
          invoiceModel,
        }),
        () => this.processOwnGiftCardPayment({
          results,
          paymentMethodCollection,
          processingView,
          totalValueWt,
        }),
        () => this.processLoyaltyPointsPayment({
          results,
          paymentMethodCollection,
          processingView,
          orderModel,
          invoiceModel,
          relationDataId: relation_data_id,
        }),
        () => this.processOtherPayment({
          results,
          paymentMethodCollection,
          totalValueWt,
          processingView,
        }),
        () => this.processPaylater({
          results,
          paymentMethodCollection,
          orderModel,
          processingView,
        }),
        () => this.processInvoicePayment({
          results,
          paymentMethodCollection,
          orderModel,
          processingView,
        }),
        () => this.processCashPayment({
          results,
          paymentMethodCollection,
          totalValueWt,
          processingView,
        }),
      ];

      Upx.eachCall(paymentCalls)
        .then(
          () => {
            const pinPayment = this.getPaymentResultByMethod(results, PaymentMethods.PIN_METHOD);
            if (pinPayment && !pinPayment.get('error')) {
              // Popup
              const {
                requestIdentification,
                requestMerchantSignature,
                requestSignature,
                isCCVPayment,
              } = pinPayment.get('paymentMethod');

              if (isCCVPayment
                && (requestIdentification || requestMerchantSignature || requestSignature)
              ) {
                const view = new MessagePopupView();

                let text = '';
                if (requestIdentification && !requestSignature) {
                  // Customer should be asked for an ID
                  text = Locale.translate('ask_customer_for_identification');
                } else if (requestIdentification && requestSignature) {
                  // Customer should be asked for ID and to place a signature
                  text = Locale.translate('ask_customer_for_identification_and_to_put_a_signature_on_receipt');
                  // Customer should be asked to place a signature
                } else if (requestSignature && !requestIdentification) {
                  text = Locale.translate('ask_customer_to_put_a_signature_on_receipt');
                } else if (requestMerchantSignature) {
                  // Cashier should place a signature
                  text = Locale.translate('place_signature_on_receipt');
                }

                view.open(text);
              }
            }

            def.resolve(results);
          },
          (error) => {
            error.paymentResults = results;
            this.refundPayments(results)
              .then(
                () => {
                  // payments failed but all was refunded correctly
                  def.reject(error); // reject with original error
                },
                (refundErr) => {
                  error.refundError = refundErr;
                  def.reject(error);
                },
              );
          },
        );

      return Promisify.deferredToPromise(def);
    },

    async processOrderSignature(orderModel, processingView, paymentResults, paymentMethodCollection) {
      if (await ReceiptSigning.isConfigured()) {
        try {
          const { total } = await Upx.callPromise(
            'ShopModule', 'listOrderInvoices',
            {
              start: 0,
              limit: 1,
              filters: [{
                name: 'order_id__=',
                val: orderModel.get('id'),
              }],
            },
          );
          const forceOnOrder = total > 0; // if it has invoices, do not set the turnover
          const data = await ReceiptSigning.signOrder(
            orderModel, processingView, paymentResults.getPaymentIds(),
            paymentMethodCollection, forceOnOrder,
          );
          orderModel.set({ transaction_signature: data });
        } catch (error) {
          console.error('Receipt signing error', error);
          orderModel.set({
            transaction_signature: {
              error: Locale.translate('failed_to_generate_receipt_signature'),
            },
          });
        }
      }
    },

    async processInvoiceSignature(invoiceModel, processingView, paymentResults, paymentMethodCollection) {
      if (await ReceiptSigning.isConfigured()) {
        try {
          const data = await ReceiptSigning.signInvoicePayments(
            invoiceModel, processingView, paymentResults.getPaymentIds(),
            paymentMethodCollection,
          );
          invoiceModel.set({ transaction_signature: data });
        } catch (error) {
          console.error('Receipt signing error', error);
          invoiceModel.set({
            transaction_signature: {
              error: Locale.translate('failed_to_generate_receipt_signature'),
            },
          });
        }
      }
    },

    processFailedInvoicePayments({
      error,
      number,
      invoiceId,
      processingView,
      paymentMethodCollection,
    }) {
      if (!error) throw new Error('Missing error');
      if (!number) throw new Error('Missing number');
      if (!invoiceId) throw new Error('Missing invoiceId');
      if (!processingView) throw new Error('Missing processingView');
      if (!paymentMethodCollection) throw new Error('Missing paymentMethodCollection');

      const { paymentResults = null } = error;
      if (paymentResults) {
        this.lockSuccessfulPaymentMethods({
          paymentResults,
          paymentMethodCollection,
        });

        this.showPaymentErrorPopup({
          processingView,
          error,
          number,
        });

        this.attachInvoicePayments({
          paymentResults,
          processingView,
          invoiceId,
        }).catch((attachError) => console.error(attachError)); // Ensure sentry knows about it.
      }
    },

    processFailedOrderPayments({
      error,
      number,
      orderId,
      processingView,
      paymentMethodCollection,
    }) {
      if (!error) throw new Error('Missing error');
      if (!number) throw new Error('Missing number');
      if (!orderId) throw new Error('Missing orderId');
      if (!processingView) throw new Error('Missing processingView');
      if (!paymentMethodCollection) throw new Error('Missing paymentMethodCollection');

      const { paymentResults = null } = error;
      if (paymentResults) {
        this.lockSuccessfulPaymentMethods({
          paymentResults,
          paymentMethodCollection,
        });

        this.showPaymentErrorPopup({
          processingView,
          error,
          number,
        });

        this.attachOrderPayments({
          paymentResults,
          processingView,
          orderId,
        }).catch((attachError) => console.error(attachError));
      }
    },

    lockSuccessfulPaymentMethods({ paymentResults, paymentMethodCollection }) {
      if (!paymentResults) throw new Error('Missing paymentResults');
      if (!paymentMethodCollection) throw new Error('Missing paymentMethodCollection');

      paymentResults.each((paymentResult) => {
        if (paymentResult.get('error')) {
          CashRegisterApi.logAction('PAYMENT_ERROR', paymentResult.toJSON());
        }
        if (paymentResult.get('refundError')) {
          CashRegisterApi.logAction('PAYMENT_ERROR_ON_REFUND', paymentResult.toJSON());
        }

        // lock the ones which were successful, but not refunded
        // the unsuccessful can stay, so they can be retried
        // the refunded, can be just done once more
        if (!paymentResult.get('error') && !paymentResult.get('refunded')) {
          paymentMethodCollection.lockMethod(paymentResult.get('paymentMethod').id);
        }
      });
    },

    showPaymentErrorPopup({
      error,
      number,
      processingView,
    }) {
      if (!error) throw new Error('Missing error');
      if (!number) throw new Error('Missing number');
      if (!processingView) throw new Error('Missing processingView');

      const view = new PaymentErrorPopup();
      view.open(error, number, processingView.collection, processingView.type);
    },

    async attachOrderPayments({
      orderId,
      processingView,
      paymentResults,
    }) {
      if (!orderId) throw new Error('Missing orderId');
      if (!processingView) throw new Error('Missing processingView');
      if (!paymentResults) throw new Error('Missing paymentResults');

      const log = processingView.attachPayments();
      try {
        await PaymentAttachment.scheduleAttachPaymentsToOrder({
          orderId, paymentIds: paymentResults.getPaymentIds(),
        });

        log.success();
      } catch (err) {
        CashRegisterApi.logAction('PAYMENT_ERROR_ON_ATTACH_TO_ORDER', {
          error: err,
          paymentIds: paymentResults.getPaymentIds(),
          orderId,
        });

        log.error(err);
        throw err;
      }
    },

    async attachInvoicePayments({
      invoiceId,
      processingView,
      paymentResults,
    }) {
      if (!invoiceId) throw new Error('Missing invoiceId');
      if (!processingView) throw new Error('Missing processingView');
      if (!paymentResults) throw new Error('Missing paymentResults');

      const log = processingView.attachPayments();
      try {
        await PaymentAttachment.scheduleAttachPaymentsToInvoice({
          invoiceId, paymentIds: paymentResults.getPaymentIds(),
        });

        log.success();
      } catch (err) {
        CashRegisterApi.logAction('PAYMENT_ERROR_ON_ATTACH_TO_INVOICE', {
          error: err,
          paymentIds: paymentResults.getPaymentIds(),
          invoiceId,
        });

        log.error(err);

        throw err;
      }
    },

    getIsPaymentProduct(name, ppu_wt) {
      return {
        sku: 'PAYMENTS',
        name,
        ppu_wt,
        tax: 0,
        quantity: 1,
        is_payment: true,
      };
    },

    attachPaymentProductMethod(paymentMethodCollection, method, paymentProducts, rounding) {
      const paymentModel = paymentMethodCollection.get(method);
      if (paymentModel) {
        const value = Currency.toCurrency(paymentModel.get('ppu_wt') || '0.00');
        if (value !== '0.00') {
          paymentProducts.push(
            this.getIsPaymentProduct(
              paymentModel.get('title'),
              Currency.Math.mul(value, '-1.00'),
            ),
          );
          rounding = Currency.Math.add(rounding, value);
        }
      }
      return rounding;
    },

    applyPaymentProductRounding(amount_wt, paymentProducts, paymentMethodCollection) {
      let rounding = amount_wt;
      paymentProducts.forEach((product) => {
        const ppu_wt = Currency.toCurrency(product.ppu_wt);
        const price_wt = Currency.Math.mul(ppu_wt, Currency.toCurrency(product.quantity));
        rounding = Currency.Math.subtract(rounding, price_wt);
      });
      if (rounding !== '0.00') {
        rounding = this.attachPaymentProductMethod(
          paymentMethodCollection,
          paymentMethodCollection.PAYLATER_METHOD,
          paymentProducts,
          rounding,
        );
        rounding = this.attachPaymentProductMethod(
          paymentMethodCollection,
          paymentMethodCollection.INVOICE_METHOD,
          paymentProducts,
          rounding,
        );
        if (rounding !== '0.00') {
          paymentProducts.push(
            this.getIsPaymentProduct(
              Locale.translate('other_payments'),
              rounding,
            ),
          );
        }
      }
    },

    processQrPayment({
      results,
      totalValueWt,
      processingView,
      def = new $.Deferred(),
      paymentMethodCollection,
      paymentProducts,
      relation_data_id,
    }) {
      const qrMethodAlias = paymentMethodCollection.QR_CODE_METHOD;
      const qrModel = paymentMethodCollection.get(qrMethodAlias);
      if (qrModel) {
        let amount_wt = '0.00';
        if (qrModel) {
          amount_wt = Currency.Math.add(
            amount_wt,
            this.getAmountOrRest(
              qrModel,
              totalValueWt,
              paymentMethodCollection.getTotalPaymentAgainstOrderWt(),
            ),
          );

          if (paymentProducts && paymentProducts.length) {
            this.applyPaymentProductRounding(amount_wt, paymentProducts, paymentMethodCollection);
          }

          const provider_id = PaymentProviderCollection.getUpxPayProviderId();
          const qrPaymentModel = new PaymentModel({
            provider_id,
            currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
            amount: amount_wt,
            title: processingView.getTitle(),
            products: paymentProducts,
            relation_data_id,
          });

          CashRegisterApi.logAction('PAYMENT_QR_START', {
            amount: amount_wt,
            provider_id,
          });

          const log = processingView.payment(
            qrMethodAlias,
            new Backbone.Model({
              description: qrModel.get('description'),
              amount: amount_wt,
            }),
          );
          log.setProcessingStatus();
          const originalQrName = paymentMethodCollection.getQrMethodName();
          qrModel.set('title', originalQrName);

          Upx.call('PaymentModule', 'newUpxPayProviderQrPaymentWithReturn', {
            fields: qrPaymentModel.toJSON(),
          }).then(
            (payment) => {
              const payment_id = payment.id;
              qrPaymentModel.set('id', payment_id);

              if (!payment.metadata || !payment.metadata.qrUrl || !payment.metadata.qrUuid) {
                // this should not happend, because backend checks it as well
                def.reject({
                  error: Locale.translate('no_qr_was_generated'),
                });
              } else {
                log.setStatus(Locale.translate('waiting_for_customer'));

                const { qrUrl, qrMethodIds = [] } = payment.metadata;
                this.generateQR(qrUrl)
                  .then(
                    (qrImageUrl) => {
                      const enabledMethods = new Backbone.Collection(
                        PaymentProviderMethodCollection.filter((model) => qrMethodIds.indexOf(model.get('id')) !== -1),
                      );
                      const supportedAppImages = [];
                      _.each(PaymentMethod.ALL_PROFILES, (id) => {
                        if (enabledMethods.findWhere({ eid: id.toString() })) {
                          supportedAppImages.push(
                            PaymentMethod.getPaymentImageUrl(id),
                          );
                        }
                      });

                      CustomerScreenData.displayQrInformation(
                        qrImageUrl, null, null, supportedAppImages,
                      );

                      log.setQrImageUrl(qrUrl, qrImageUrl, qrMethodIds);
                      log.on(
                        'change',
                        () => {
                          // send the updates to customer screen
                          if (log.has('qrFinalSuccess')) {
                            if (log.get('qrFinalSuccess')) {
                              CustomerScreenData.displayQrSuccessInformation(log.get('qrFinalStatus'));
                            } else {
                              CustomerScreenData.displayQrErrorInformation(log.get('qrFinalStatus'));
                            }
                          } else {
                            CustomerScreenData.displayQrInformation(
                              qrImageUrl,
                              log.get('qrOverlayImageUrl'),
                              log.get('qrStatus'),
                              supportedAppImages,
                            );
                          }
                        },
                      );

                      log.on(
                        'payment:cancel',
                        (btnDef) => {
                          qrPaymentModel.cancel().then(
                            () => {
                              const message = Locale.translate('payment_canceled');
                              log.error(message);
                              btnDef.resolve();
                              def.reject({ error: message });
                            },
                            (err) => {
                              console.error('Failed to cancel payment', {
                                payment_id,
                                err,
                              });
                              btnDef.reject(err);
                              def.reject({ error: Locale.translate('payment_canceled') });
                            },
                          );
                        },
                      );

                      log.on(
                        'change:qrMethod',
                        () => {
                          const name = log.get('qrMethod');
                          if (name) {
                            qrModel.set('title', name);
                          } else {
                            qrModel.set('title', originalQrName);
                          }
                        },
                      );

                      def.always(() => {
                        setTimeout(
                          // to wait for the animation finish
                          () => CustomerScreenData.removeQrInformation(),
                          15,
                        );
                      });

                      CashRegisterApi.logAction('PAYMENT_QR_SAVE_IN_BACKEND', {
                        payment_id,
                        amount: amount_wt,
                        provider_id,
                        qrUrl,
                      });

                      let paymentExpiry = new Date().getTime() + 30 * 60 * 1000; // 30 minutes
                      if (payment.date_expires_on) {
                        paymentExpiry = new Date(payment.date_expires_on).getTime();
                      }

                      this.syncQrPayment(log, payment, paymentExpiry)
                        .then(() => {
                          // for success screen afterwards and receipt
                          qrModel.set('ppu_wt', amount_wt);

                          results.addSuccessful(qrModel, payment_id, true);

                          log.success();
                          def.resolve();
                        },
                        (error) => {
                          log.error(error);
                          results.addError(qrModel, error, payment_id);
                          def.reject(error);
                        });
                    },
                    (err) => {
                      console.error('Failed to generate QR image', err);
                      def.reject({
                        error: Locale.translate('failed_to_generate_qr_image'),
                      });
                    },
                  );
              }
            },
            (error) => {
              results.addError(qrModel, error);
              const message = error.error || error.message;
              log.error(message);
              def.reject({
                error: message,
              });
            },
          );
        }
      } else {
        def.resolve();
      }
      return def.promise();
    },

    generateQR(text, options = {}) {
      const def = new $.Deferred();
      const generateOptions = $.extend(
        {
          errorCorrectionLevel: 'H',
          margin: 0,
        },
        options || {},
      );
      Qrcode.toDataURL(text, generateOptions, (err, url) => {
        if (err) {
          def.reject(err);
        } else {
          def.resolve(url);
        }
      });

      return def;
    },
    processPinPayment({
      results,
      retryI = 1,
      totalValueWt,
      processingView,
      cashierDisplay,
      def = new $.Deferred(),
      paymentMethodCollection,
      orderModel,
      invoiceModel,
    }) {
      const retryLimit = 3;
      const pinMethodAlias = paymentMethodCollection.PIN_METHOD;
      const pinModel = paymentMethodCollection.get(pinMethodAlias);
      const pinExtraMethodAlias = paymentMethodCollection.PIN_EXTRA_METHOD;
      const pinExtraModel = paymentMethodCollection.get(pinExtraMethodAlias);

      if (pinModel) {
        if (!this.pinTerminalIsSelected()) {
          def.reject({
            error: Locale.translate('no_pin_terminal_selected'),
          });
        } else if (TerminalSetting.isCCVPin() && !CCVPin.isAvailable()) {
          def.reject({
            error: Locale.translate('ccv_pin_terminal_is_not_available'),
          });
        } else {
          let amount_wt = '0.00';
          if (pinModel) {
            amount_wt = Currency.Math.add(
              amount_wt,
              this.getAmountOrRest(
                pinModel,
                totalValueWt,
                paymentMethodCollection.getTotalPaymentAgainstOrderWt(),
              ),
            );
          }
          const amount_without_extra_wt = amount_wt;
          let extraPinDescription = '';
          if (pinExtraModel && pinExtraModel.get('ppu_wt') > 0) {
            amount_wt = Currency.Math.add(
              amount_wt,
              pinExtraModel.get('ppu_wt'),
            );
            extraPinDescription = ` ${Locale.translate(
              'cash_withdrawal_{amount}', {
                amount: pinExtraModel.get('ppu_wt'),
              },
            )}`;
          }
          if (pinModel.get('refund_amount')) {
            amount_wt = Currency.Math.add(
              amount_wt,
              pinModel.get('refund_amount'),
            );
          }
          const pinPaymentModel = new PaymentModel({
            provider_id: TerminalSetting.get('provider_id'),
            provider_method_id: TerminalSetting.get('id'),
            currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
            amount: amount_wt,
            title: processingView.getTitle(),
            description: Locale.translate('terminal_device_{terminal}', {
              terminal: TerminalSetting.get('title'),
            }) + extraPinDescription,
          });

          CashRegisterApi.logAction('PAYMENT_PIN_START', {
            amount: amount_wt,
            provider_id: TerminalSetting.get('provider_id'),
            provider_method_id: TerminalSetting.get('id'),
            terminal: TerminalSetting.get('title'),
          });
          const log = processingView.payment(
            pinMethodAlias,
            new Backbone.Model({
              description: pinModel.get('description'),
              amount: amount_wt,
            }),
            retryI,
          );
          log.setProcessingStatus();
          pinPaymentModel.newWithReturn()
            .then(
              (payment) => {
                const payment_id = payment.id;

                log.setStatus(Locale.translate('waiting_for_customer'));

                CashRegisterApi.logAction('PAYMENT_PIN_SAVE_IN_BACKEND', {
                  payment_id,
                  amount: amount_wt,
                  provider_id: TerminalSetting.get('provider_id'),
                  provider_method_id: TerminalSetting.get('id'),
                  terminal: TerminalSetting.get('title'),
                });

                let paymentExpiry = new Date().getTime() + 60 * 1000;

                if (payment.date_expires_on) {
                  paymentExpiry = new Date(payment.date_expires_on).getTime();
                }

                this.syncPinPayment({
                  payment,
                  paymentExpiry,
                  cashierDisplay,
                  orderModel,
                  invoiceModel,
                })
                  .then((paidPayment) => {
                    // for success screen afterwards and receipt
                    let receipt = '';
                    if (paidPayment.payment_vars) {
                      paidPayment.payment_vars.forEach((v) => {
                        if (v.name === 'receipt') {
                          receipt = v.value;
                          return false;
                        }
                      });
                    }

                    const ccvProvider = PaymentProviderCollection.getByTypeAlias(
                      PaymentProviderCollection.TYPE_ALIAS_CCV_PIN_ATTENDED_OPI,
                    );
                    const isCCVPayment = ccvProvider && paidPayment.provider.id === ccvProvider.get('id');
                    pinModel.set('isCCVPayment', isCCVPayment);

                    pinModel.set('ppu_wt', amount_without_extra_wt);
                    pinModel.set('pinReceipt', receipt); // used for printing

                    if (paidPayment.metadata && paidPayment.metadata.authorisation) {
                      const auth = paidPayment.metadata.authorisation;

                      pinModel.set('requestIdentification', auth.requestIdentification || false);
                      pinModel.set('requestMerchantSignature', auth.requestMerchantSignature || false);
                      pinModel.set('requestSignature', auth.requestSignature || false);
                    }

                    results.addSuccessful(pinModel, payment_id);
                    if (pinExtraModel) {
                      results.addSuccessful(pinExtraModel, payment_id);
                    }

                    log.success();
                    def.resolve();
                  },
                  (error) => {
                    log.error(error);
                    results.addError(pinModel, error, payment_id);
                    if (pinExtraModel) {
                      results.addError(pinExtraModel, error, payment_id);
                    }
                    def.reject(error);
                  });
              },
              (error) => {
                let message = '';
                let shouldRetry = false;
                switch (error.class) {
                  case 'PaymentModule::TerminalNotAllowed':
                    message = Locale.translate('please_choose_a_different_pin_terminal_dot');
                    break;
                  case 'PaymentModule::TerminalNotConnected':
                    message = Locale.translate('pin_terminal_not_connected_dot');
                    break;
                  case 'PaymentModule::PaymentProvider':
                    message = Locale.translate('pin_payment_has_failed_to_start_dot');
                    break;
                  case 'PaymentModule::TerminalInUse':
                    message = Locale.translate('pin_terminal_is_in_use_dot');
                    shouldRetry = retryI <= retryLimit; // <= cos count from 1, 3 retries
                    break;
                  default:
                    message = Locale.translate('pin_payment_could_not_be_processed_dot');
                }

                results.addError(pinModel, error);
                log.error(message);

                if (shouldRetry) {
                  retryI++;
                  const retryLog = processingView.payment(pinMethodAlias, pinModel, retryI);
                  // 4s is the time the on screen message is shown on the terminal
                  // 1s extra just to be sure
                  retryLog.setStatus(Locale.translate('waiting_5s_for_terminal'));
                  setTimeout(() => {
                    this.processPinPayment({
                      results,
                      processingView,
                      totalValueWt,
                      retryI,
                      def,
                      paymentMethodCollection,
                      orderModel,
                      invoiceModel,
                    });
                  }, 5000);
                } else {
                  def.reject({
                    error: message,
                  });
                }
              },
            );
        }
      } else {
        def.resolve();
      }
      return def.promise();
    },

    processOwnGiftCardPayment({
      results,
      processingView,
      paymentMethodCollection,
      totalValueWt,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.GIFTCARD_METHOD;
      const is_refund = parseFloat(totalValueWt) < 0;
      if (this.hasNonEmptyPayment(methodAlias, paymentMethodCollection, is_refund)) {
        const payments = paymentMethodCollection.filterByNonemptyMethod(methodAlias, is_refund);

        const calls = [];
        let error = false;
        for (let i = 0; i < payments.length; i++) {
          const model = payments[i];
          const log = processingView.payment(methodAlias, model, null, true);
          calls.push(Upx.prepareCall(
            () => {
              const model = payments[i];
              const data = {
                fields: {
                  gift_card_id: model.get('gift_card_id'),
                  amount: is_refund ? model.get('refund_amount') : model.get('ppu_wt'),
                  currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
                  title: Locale.translate('paid_with_gift_card'),
                  description: Locale.translate('balance_{0}_used_{1}', [model.get('balance'), model.get('ppu_wt')]),
                },
              };

              if (is_refund) {
                data.fields.gift_card_details = {
                  code: model.get('code'),
                  provider_id: GiftCardPaymentProvider.get('id'),
                  relation_data_id: model.get('original_data.relation_data_id'),
                  is_anonymous: !model.get('original_data.relation_data_id'),
                  in_stock: false,
                };
              }
              const giftCardPaymentModel = new GiftCardPaymentModel(data);

              log.setProcessingStatus();

              if (is_refund) {
                return giftCardPaymentModel.refund();
              }

              return giftCardPaymentModel.ensure();
            },
            (payment_id) => {
              log.success();
              results.addSuccessful(payments[i], payment_id);
            },
            (paymentResponse) => {
              const model = payments[i];
              // it`s ok sot store cos we stop on first error
              error = Locale.translate(
                'failed_process_gift_card_{0}_with_the_following_reason_{1}',
                [model.get('code'), paymentResponse.error],
              );
              log.success(error);
              results.addError(model, error);
            },
          ));
        }

        this.doMultipleCalls(calls, def);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    doMultipleCalls(calls, def, resolveCallback = null, rejectCallBack = null) {
      if (!resolveCallback) {
        resolveCallback = () => {
          def.resolve();
        };
      }

      if (!rejectCallBack) {
        rejectCallBack = ({ error }) => {
          def.reject({ error });
        };
      }

      Upx.eachCall(calls).then(resolveCallback, rejectCallBack);
    },

    processOtherPayment({
      results,
      totalValueWt,
      processingView,
      paymentMethodCollection,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.OTHER_METHOD;

      const model = paymentMethodCollection.get(methodAlias);
      const is_refund = parseFloat(totalValueWt) < 0;
      if (model) {
        const amount_wt = is_refund ? model.get('refund_amount') : this.getAmountOrRest(
          model,
          totalValueWt,
          paymentMethodCollection.getTotalPaymentAgainstOrderWt(),
        );
        const otherPaymentModel = new PaymentModel({
          provider_id: model.get('provider_id'),
          provider_method_id: model.get('provider_method_id'),
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
          amount: amount_wt,
          title: Locale.translate('paid_with_other'),
        });
        const log = processingView.payment(methodAlias, model);
        log.setProcessingStatus();
        otherPaymentModel.save()
          .then((payment_id) => {
            // pay the method
            otherPaymentModel.set({
              id: payment_id,
              status: 'paid',
            });
            otherPaymentModel.save()
              .then(
                () => {
                  results.addSuccessful(model, payment_id);
                  def.resolve();
                },
                (error) => {
                  results.addError(model, error, payment_id);
                  def.reject(error);
                },
              );
          }, (error) => {
            results.addError(model, error);
            def.reject(error);
          });
        log.def(def);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    processLoyaltyPointsPayment({
      results,
      processingView,
      paymentMethodCollection,
      orderModel = null,
      invoiceModel = null,
      relationDataId,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.LOYALTY_POINTS_METHOD;

      const model = paymentMethodCollection.get(methodAlias);
      if (model) {
        const amount = model.get('ppu_wt');
        const loyaltyProgramId = model.get('loyaltyProgramId');

        let referenceNumber = '';

        if (orderModel && orderModel.get('number')) {
          referenceNumber = orderModel.get('number');
        }

        if (!referenceNumber && invoiceModel && invoiceModel.get('number')) {
          referenceNumber = invoiceModel.get('number');
        }

        const loyaltyProgramPointsPaymentModel = new LoyaltyProgramPointsPaymentModel({
          loyalty_program_id: loyaltyProgramId,
          relation_data_id: relationDataId,
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
          amount,
          title: Locale.translate('using_loyalty_points'),
          reference_no: referenceNumber,
        });

        const log = processingView.payment(methodAlias, model);
        log.setProcessingStatus();
        loyaltyProgramPointsPaymentModel.save()
          .then((payment_id) => {
            results.addSuccessful(model, payment_id);
            def.resolve();
          }, (error) => {
            results.addError(model, error);
            def.reject(error);
          });
        log.def(def);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    processPaylater({
      results,
      orderModel,
      processingView,
      paymentMethodCollection,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.PAYLATER_METHOD;

      const model = paymentMethodCollection.get(methodAlias);
      if (model) {
        const log = processingView.payment(methodAlias, model);
        const providerMethodId = PaymentProviderMethodCollection
          .getProviderMethodIdByAlias(PaymentProviderMethodCollection.ONORDER_ALIAS);
        log.setProcessingStatus();

        let statusDef = true;
        if (orderModel.get('status') === 'concept') {
          statusDef = orderModel.updateStatus({
            fields: {
              status: 'new',
            },
            id: orderModel.get('id'),
          });
        }
        $.when(
          statusDef,
          orderModel.updateWithoutItems({
            fields: {
              provider_method_id: providerMethodId,
            },
            id: orderModel.get('id'),
          }),
        ).then(
          () => {
            // pay the method
            results.addSuccessful(model);
            def.resolve();
          }, (error) => {
            results.addError(model, error);
            def.reject(error);
          },
        );
        log.def(def);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    buildInvoiceForOrder(orderModel) {
      const order_invoice_rows = [];
      if (orderModel.has('order_items')) {
        orderModel.get('order_items')
          .forEach((item) => {
            order_invoice_rows.push({
              order_item_id: item.id,
              quantity: item.quantity,
            });

            const subitems = item.subitems || item.sub_items || [];
            subitems.forEach((subitem) => {
              order_invoice_rows.push({
                order_item_id: subitem.id,
                quantity: subitem.quantity,
              });
            });
          });
      }
      const orderInvoiceModel = new OrderInvoiceModel({
        order_id: orderModel.get('id'),
        order_invoice_rows,
      });
      return orderInvoiceModel;
    },

    processInvoicePayment({
      results,
      orderModel,
      processingView,
      paymentMethodCollection,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.INVOICE_METHOD;

      const model = paymentMethodCollection.get(methodAlias);
      if (model) {
        const log = processingView.payment(methodAlias, model);
        const providerMethodId = PaymentProviderMethodCollection
          .getProviderMethodIdByAlias(PaymentProviderMethodCollection.ONINVOICE_ALIAS);
        log.setProcessingStatus();
        orderModel.updateWithoutItems({
          fields: {
            provider_method_id: providerMethodId,
          },
          id: orderModel.get('id'),
        })
          .then(
            () => {
              log.setStatus(Locale.translate('creating_invoice'));
              // create the invoice
              const orderInvoiceModel = this.buildInvoiceForOrder(orderModel);
              orderInvoiceModel.save()
                .then(
                  (invoiceId) => {
                    // pay the method
                    results.addSuccessful(model)
                      .set('invoiceId', invoiceId);
                    def.resolve();
                  },
                  (error) => {
                    results.addError(model, error);
                    def.reject(error);
                  },
                );
            }, (error) => {
              results.addError(model, error);
              def.reject(error);
            },
          );
        log.def(def);
      } else {
        def.resolve();
      }
      return def.promise();
    },

    hasSpareChange(paymentMethodCollection) {
      return parseFloat(paymentMethodCollection.getSpareChangeWt()) > 0;
    },

    processCashPayment({
      results,
      totalValueWt,
      processingView,
      paymentMethodCollection,
    }) {
      const def = new $.Deferred();
      const methodAlias = paymentMethodCollection.CASH_METHOD;
      let model = paymentMethodCollection.get(methodAlias);
      let cashPaymentModel = null;
      if (!model) {
        // no cash model lets check if there is rest
        if (this.hasSpareChange(paymentMethodCollection)) {
          // there is spare change lets create model for it
          const changeReturned = paymentMethodCollection.getSpareChangeWt();
          cashPaymentModel = new PaymentModel({
            amount: `-${changeReturned}`,
            description: Locale.translate('spare_change_given_{0}', changeReturned),
            changeReturned,
          });

          const cash_alias = PaymentMethodsSettingModel.CASH_METHOD;
          model = new PaymentMethodItemModel({
            id: cash_alias,
            title: PaymentMethodsSettingModel.getNameByMethod(cash_alias),
            rest_value: PaymentMethodsSettingModel.allowsRestPaidByMethod(cash_alias),
            lockedAlreadyPaid: false,
          });
        }
      } else {
        // Check if the order total is negative / is a refund.
        // When a orderTotalWt is negative, only a cash payment is possible.
        if (parseFloat(totalValueWt) < 0) {
          // Calculate if anyone added some amount to contant
          const paymentPpuWt = model.get('ppu_wt');
          const orderTotalWtPositive = Math.abs(parseFloat(totalValueWt));
          let description = Locale.translate('amount_refunded_{0}', Currency.toCurrency(orderTotalWtPositive));
          let changeReturned = totalValueWt;
          // Check if the customer gave some money, it should be refunded
          if (parseFloat(paymentPpuWt) > 0) {
            changeReturned = paymentMethodCollection.getSpareChangeWt();
            description += `, ${Locale.translate('cash_received_{0}', paymentPpuWt)}`;
            description += `, ${Locale.translate('spare_change_given_{0}', changeReturned)}`;
          }

          // Setting data to model
          cashPaymentModel = new PaymentModel({
            amount: totalValueWt,
            description,
            cashReceived: paymentPpuWt,
            cashRefunded: orderTotalWtPositive,
            changeReturned,
          });
        } else {
          // regular order. process as normally.
          const cashReceived = this.getAmountOrRest(
            model,
            totalValueWt,
            paymentMethodCollection.getTotalPaymentAgainstOrderWt(),
          );
          const changeReturned = paymentMethodCollection.getSpareChangeWt();
          const cash_paid_wt = Currency.Math.subtract(cashReceived, changeReturned);

          cashPaymentModel = new PaymentModel({
            amount: cash_paid_wt,
            description: Locale.translate('received_{0}_spare_change_{1}', [cashReceived, changeReturned]),
            cashReceived,
            changeReturned,
          });
        }
      }

      if (cashPaymentModel) {
        cashPaymentModel.set({
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
          title: Locale.translate('paid_by_cash'),
        });
        const log = processingView
          .payment(PaymentMethodsSettingModel.CASH_METHOD, cashPaymentModel);
        // there is model created
        cashPaymentModel.newCash()
          .then(
            (payment_id) => {
              results.addSuccessful(model, payment_id);
              log.success();
              def.resolve();
            },
            (error) => {
              results.addError(model, error);
              log.error(error);
              def.reject(error);
            },
          );
      } else {
        def.resolve();
      }
      return def.promise();
    },

    getTotalWtOfLockedItems(paymentMethodCollection) {
      let total = '0.00';
      paymentMethodCollection.each((payment) => {
        if (payment.get('lockedAlreadyPaid')) {
          total = Currency.Math.add(
            total,
            Currency.toCurrency(payment.get('ppu_wt')),
          );
        }
      });
      return total;
    },

    getTotalLockedItems(paymentMethodCollection) {
      return paymentMethodCollection.where({
        lockedAlreadyPaid: true,
      });
    },

    async processCashRefundForLockedOrderItems({
      orderId,
      paymentMethodCollection,
    }) {
      const total = this.getTotalWtOfLockedItems(paymentMethodCollection);
      if (total !== '0.00') {
        const items = this.getTotalLockedItems(paymentMethodCollection);
        const cashPaymentModel = new PaymentModel({
          amount: `-${total}`,
          title: Locale.translate('manual_cash_refund'),
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
        });

        const paymentId = await Promisify.deferredToPromise(cashPaymentModel.newCash());
        await PaymentAttachment.scheduleAttachPaymentsToOrder({ orderId, paymentIds: [paymentId] });

        for (let i = 0; i < items.length; i++) {
          paymentMethodCollection.remove(items[i]);
        }
      }
    },

    async processCashRefundForLockedInvoiceItems({
      invoiceId,
      paymentMethodCollection,
    }) {
      const total = this.getTotalWtOfLockedItems(paymentMethodCollection);
      if (total !== '0.00') {
        const items = this.getTotalLockedItems(paymentMethodCollection);
        const cashPaymentModel = new PaymentModel({
          amount: `-${total}`,
          title: Locale.translate('manual_cash_refund'),
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
        });

        const paymentId = await Promisify.deferredToPromise(cashPaymentModel.newCash());
        await PaymentAttachment.scheduleAttachPaymentsToInvoice({
          invoiceId, paymentIds: [paymentId],
        });

        for (let i = 0; i < items.length; i++) {
          paymentMethodCollection.remove(items[i]);
        }
      }
    },

    reportPaymentExpiry(payment_id, def, payment, logPrefix = false) {
      logPrefix = logPrefix || `[Payment ID:${payment_id}]`;
      console.warn(`${logPrefix} expired`);
      CashRegisterApi.logAction('PAYMENT_PIN_SYNC_MAX_EXCEEDED', {
        payment_id,
        payment,
        terminal: TerminalSetting.get('title'),
      });
      def.reject({
        error: Locale.translate('payment_expired_because_of_bad_connectivity_dot'),
      });
    },

    checkIfExpired(paymentExpiry, logPrefix = '[Payment]') {
      if (paymentExpiry) {
        console.debug(`${logPrefix} Payment will expire in ${paymentExpiry - new Date().getTime()}`);
        return new Date().getTime() > paymentExpiry;
      }
      console.debug(`${logPrefix} Payment will not expire `);

      return false;
    },

    syncPaymentFromBackend(payment_id, paymentExpiry, def, errorTries, logPrefix) {
      // sometimes the sync fail then we retry, up to 3 times
      // unknown problem with postgreSQL row locking by other process while syncing
      const maxRetries = 5;
      const retryWait = 2000; // wait half second maybe the locks clean up
      errorTries = errorTries || 0;
      const self = this;

      logPrefix = logPrefix || `[Payment ID:${payment_id}]`;
      console.debug(`${logPrefix} sync from backend (try:${errorTries}, expire:${paymentExpiry})`);
      if (ConnectionComponent.isOffline()) {
        console.warn(`${logPrefix} offline -> retry later (try:${errorTries}, expire:${paymentExpiry})`);
        setTimeout(() => {
          self.syncPaymentFromBackend(payment_id, paymentExpiry, def, errorTries);
        }, 3000);
        return;
      }
      // when running in backup mode on backend we need to check the expiry as well
      if (this.checkIfExpired(paymentExpiry, logPrefix)) {
        this.reportPaymentExpiry(payment_id, def, null, logPrefix);
        return;
      }

      // final -> do the backend sync to make sure backend also knows the status
      const paymentModel = new PaymentModel();
      paymentModel.syncWithReturn({ id: payment_id })
        .then((paymentData) => {
          const paymentStatus = paymentData.status;

          if (paymentData.date_expires_on) {
            paymentExpiry = new Date(paymentData.date_expires_on).getTime();
          }
          if (paymentStatus === 'open') {
            // payment still open
            setTimeout(() => {
              self.syncPaymentFromBackend(payment_id, paymentExpiry, def);
            }, 5000); // wait 3s before next sync
          } else if (paymentStatus === 'paid') {
            // paid lets resolve and success the order
            def.resolve(paymentData);
          } else {
            const translatedPaymentStatus = Locale.translate(paymentStatus);
            def.reject({
              error: Locale.translate('payment_{0}', translatedPaymentStatus),
            });
          }
        }, (err) => {
          if (errorTries < maxRetries) {
            console.error(`${logPrefix} Payment synchronization failed ${errorTries + 1}/${maxRetries} ID: ${payment_id}`, err);

            // we can still retry
            // we need to check more
            setTimeout(() => {
              self.syncPaymentFromBackend(payment_id, paymentExpiry, def, errorTries + 1);
            }, retryWait);
          } else {
            // we tried, still fails -> return error
            def.reject(err);
          }
        });
    },

    syncQrPayment(log, payment, paymentExpiry, def = new $.Deferred(), errorTries = 0) {
      const self = this;

      // retrying in case the pin service fails
      const maxRetries = 5;
      const retryWait = 2000;
      const payment_id = payment.id;
      const { qrUuid } = payment.metadata;

      if (payment.date_expires_on) {
        paymentExpiry = new Date(payment.date_expires_on).getTime();
      }
      const logPrefix = `[QrPayment ID:${payment_id} ${qrUuid}]`;
      console.debug(`${logPrefix}  sync from provider (try:${errorTries}, expire:${paymentExpiry})`);
      if (ConnectionComponent.isOffline()) {
        console.warn(`${logPrefix} offline -> retry later (try:${errorTries}, expire:${paymentExpiry})`);
        setTimeout(() => {
          self.syncQrPayment(log, payment, paymentExpiry, def);
        }, 3000);
      } else if (this.checkIfExpired(paymentExpiry)) {
        // check if we exceeded the paymentExpiry
        this.reportPaymentExpiry(payment_id, def, payment, logPrefix);
      } else {
        try {
          // we can get status directly from the pin server
          const url = `https://safe.pay.nl/api/status/${qrUuid}`;
          $.ajax({
            url,
            dataType: 'json',
            timeout: 200,
          })
            .then((response) => {
              const startNextSync = () => {
                setTimeout(() => {
                  self.syncQrPayment(log, payment, paymentExpiry, def);
                }, 1000);
              };
              const {
                statusCode = '',
                paymentProfileId = 0,
                paymentProfileIssuerId = 0,
              } = response;
              const isPaid = statusCode === QR_STATUS_CODE_PAID;
              const isCanceled = statusCode === QR_STATUS_CODE_CANCELLED;
              const isExpired = statusCode === QR_STATUS_CODE_EXPIRED;
              const isVerify = statusCode === QR_STATUS_CODE_VERIFY;

              const isFinalStatus = isPaid || isCanceled || isExpired;
              if (isVerify) {
                // automatically decline payments in VERIFY state
                // according to pay.nl it's ment for credit cards
                // and it's really unlikely for it to happen
                log.setQrFinalStatus(
                  false,
                  Locale.translate('payment_failed'),
                );
                const qrPaymentModel = new PaymentModel({ id: payment_id });
                const cancelDef = qrPaymentModel.cancel();
                cancelDef.fail(
                  (err) => {
                    console.error(`${logPrefix}Failed to cancel payment`, {
                      payment_id,
                      err,
                    });
                  },
                );
                cancelDef.always(() => {
                  console.error(`${logPrefix} Transaction was auto-canceled, because in VERIFY state`);
                  const error = Locale.translate('automatic_fraud_detection_finds_this_qr_payment_suspicious_transaction_was_declined');
                  def.reject({
                    error,
                  });
                });
              } else if (isFinalStatus) {
                if (isCanceled) {
                  log.setQrFinalStatus(
                    false,
                    Locale.translate('the_payment_was_canceled'),
                  );
                } else if (isExpired) {
                  log.setQrFinalStatus(
                    false,
                    Locale.translate('the_payment_has_expired'),
                  );
                } else {
                  log.setQrFinalStatus(
                    true,
                    Locale.translate('you_have_paid_successfully'),
                    PaymentMethod.getQrPaymentMethod(paymentProfileId),
                  );
                }
                // we don`t pass the payment expiry to make sure it
                // sync the final status of the payment
                self.syncPaymentFromBackend(payment_id, undefined, def);
              } else {
                // not paid yet
                if (statusCode === QR_STATUS_CODE_SCANNED) {
                  log.setQrStatus(
                    Locale.translate('waiting_for_confirmation_in_app'),
                    PaymentMethod.getPaymentImageUrl(paymentProfileId, paymentProfileIssuerId),
                  );
                } else if (statusCode === QR_STATUS_CODE_CONFIRMED) {
                  log.setQrStatus(
                    Locale.translate('complete_the_payment_in_your_banking_app'),
                    PaymentMethod.getPaymentImageUrl(paymentProfileId, paymentProfileIssuerId),
                  );
                }
                startNextSync(); // other sync
              }
            }, (error) => {
              console.error(`${logPrefix} Failed to communicate to ${url} retrying ${errorTries + 1}/${maxRetries} ID: ${payment_id}`);

              if (errorTries < maxRetries) {
                // something wrong lets retry after 1s
                setTimeout(() => {
                  self.syncQrPayment(log, payment, paymentExpiry, def, errorTries + 1);
                }, retryWait);
              } else {
                // the pin service seems to be broken, lets fallback to backend
                CashRegisterApi.logAction('PAYMENT_QR_SYNC_SWITCHED_TO_BACKEND', {
                  url,
                  error,
                  payment,
                  qrUuid,
                });
                self.syncPaymentFromBackend(payment_id, paymentExpiry, def);
              }
            });
        } catch (e) {
          console.error(`${logPrefix}  Failed to start the payment check`, e);
          // we need to check more
          setTimeout(() => {
            self.syncQrPayment(log, payment, paymentExpiry, def, errorTries + 1);
          }, 250);
        }
      }
      return def.promise();
    },

    syncPinPayment({
      payment,
      paymentExpiry,
      def = new $.Deferred(),
      errorTries = 0,
      cashierDisplay = undefined,
      orderModel = undefined,
      invoiceModel = undefined,
    }) {
      const self = this;

      // retrying in case the pin service fails
      const maxRetries = 5;
      const retryWait = 2000;
      const payment_id = payment.id;

      if (payment.date_expires_on) {
        paymentExpiry = new Date(payment.date_expires_on).getTime();
      }

      const logPrefix = `[PinPayment ID:${payment_id}]`;
      console.log(`${logPrefix}  sync from provider (try:${errorTries}, expire:${paymentExpiry})`);
      if (ConnectionComponent.isOffline()) {
        console.log(`${logPrefix} offline -> retry later (try:${errorTries}, expire:${paymentExpiry})`);
        setTimeout(() => {
          self.syncPinPayment({
            payment, paymentExpiry, def, cashierDisplay,
          });
        }, 3000);
      } else if (this.checkIfExpired(paymentExpiry, logPrefix)) {
        // check if we exceeded the paymentExpiry
        this.reportPaymentExpiry(payment_id, def, payment, logPrefix);
      } else if (payment.metadata && payment.metadata.statusUrl) {
        try {
          // we can get status directly from the pin server
          const url = payment.metadata.statusUrl;
          $.ajax({
            url,
            dataType: 'json',
            timeout: 10000,
          })
            .then((response) => {
              if (response.status !== 'final') {
                // we need to check more
                setTimeout(() => {
                  self.syncPinPayment({ payment, paymentExpiry, def });
                }, 250);
              } else {
                // we don`t pass the payment expiry to make sure it
                // syncs when final even if it took longer than expected
                self.syncPaymentFromBackend(payment_id, undefined, def);
              }
            }, (error) => {
              console.error(`${logPrefix} Failed to communicate to ${url} retrying ${errorTries + 1}/${maxRetries} ID: ${payment_id}`);

              if (errorTries < maxRetries) {
                // something wrong lets retry after 1s
                setTimeout(() => {
                  self.syncPinPayment({
                    payment, paymentExpiry, def, errorTries: errorTries + 1,
                  });
                }, retryWait);
              } else {
                // the pin service seems to be broken, lets fallback to backend
                CashRegisterApi.logAction('PAYMENT_PIN_SYNC_SWITCHED_TO_BACKEND', {
                  url,
                  error,
                  payment,
                  terminal: TerminalSetting.get('title'),
                });
                self.syncPaymentFromBackend(payment_id, paymentExpiry, def);
              }
            });
        } catch (e) {
          console.error(`${logPrefix} Failed to start the payment check`, e);
          // we need to check more
          setTimeout(() => {
            self.syncPinPayment({
              payment, paymentExpiry, def, errorTries: errorTries + 1,
            });
          }, 250);
        }
      } else {
        const provider = PaymentProviderCollection.get(payment.provider_id);
        if (provider && provider.get('provider_type.alias') === 'SumUp') {
          // it`s sumup payment

          console.log(`${logPrefix} SumUp status:${payment.status}`);
          if (payment.status === 'expired') {
            // payment expired
            this.reportPaymentExpiry(payment_id, def, payment, logPrefix);
          } else if (payment.eid) {
            // external ID is set possible to fetch the status
            setTimeout(() => {
              self.syncPaymentFromBackend(payment_id, paymentExpiry, def);
            }, 250);
          } else if (payment.status === 'open' || payment.status === 'authorized') {
            // retry after a while
            setTimeout(() => {
              const paymentModel = new PaymentModel({ id: payment_id });
              paymentModel.fetch()
                .then(
                  () => {
                    this.syncPinPayment({
                      payment: paymentModel.toJSON(), paymentExpiry, def, errorTries,
                    });
                  },
                  (e) => {
                    console.error(`${logPrefix} Failed to fetch the payment check`, e);
                    self.syncPinPayment({
                      payment, paymentExpiry, def, errorTries: errorTries + 1,
                    });
                  },
                );
            }, 250);
          } else if (payment.status === 'error' || payment.status === 'cancelled') {
            let text = '';
            if (payment.metadata
              && payment.metadata.error
              && payment.metadata.error.error
            ) {
              text = `: ${payment.metadata.error.error}`;
            }
            def.reject({
              error: `${Locale.translate('payment_{0}', Locale.translate(payment.status))}. ${text}`,
            });
          } else {
            console.error(`${logPrefix} Unknown payments status`, payment);
            def.reject({
              error: Locale.translate('payment_{0}', Locale.translate('error')),
            });
          }
        } else if (provider && provider.get('provider_type.alias') === PaymentProviderCollection.TYPE_ALIAS_CCV_PIN_ATTENDED_OPI) {
          // It's a CCV attended opi pin payment
          this.syncCCVPinPayment({
            def,
            payment,
            cashierDisplay,
            orderModel,
            invoiceModel,
          });
        } else {
          // NO METADATA -> DO NOT HANDLE
          console.error(`${logPrefix} NO METADATA IN PAYMENT -> DO NOT HANDLE`, payment);
          def.reject({
            error: Locale.translate('payment_{0}', Locale.translate('error')),
          });
        }
      }
      return def.promise();
    },

    syncCCVPinPayment({
      def,
      payment,
      cashierDisplay,
      orderModel,
      invoiceModel,
    }) {
      $.when(
        ReceiptPrinterModel.isPrinterAvailable(),
        OpenCCVPinTransaction.hasOpenTransaction(),
      ).then((isPrinterAvailable, hasOpenTransaction) => {
        // Because CCV payments are required to have a receipt printer, we
        // need to make sure one is available.
        if (!isPrinterAvailable) {
          def.reject({
            error: Locale.translate('please_connect_a_receipt_printer_to_start_a_pin_payment'),
          });
          return;
        }

        if (hasOpenTransaction) {
          def.reject({
            error: Locale.translate('there_is_still_an_open_pin_transaction_that_needs_te_be_recovered'),
          });
          return;
        }

        const handler = new CCVPaymentHandler({
          trx: payment.trx,
        });

        if (cashierDisplay) {
          cashierDisplay.showDisplay(true);
          cashierDisplay.updateDisplay(Locale.translate('connecting_dot_dot_dot').toUpperCase());
        }

        handler.on(handler.EVENT_IPC_CONNECTION_LOST, () => {
          def.reject({
            error: Locale.translate('lost_connection_to_external_process'),
          });
        });

        handler.startPayment({
          payment,
          cashierDisplay,
          orderId: orderModel ? orderModel.get('id') : undefined,
          invoiceId: invoiceModel ? invoiceModel.get('id') : undefined,
        }).then(() => {
          this.addCCVPaymentUnknownResultHandler({
            def,
            handler,
            cashierDisplay,
            payment,
          });

          handler.on(handler.EVENT_FORCE_CANCEL, () => {
            def.reject({
              error: Locale.translate('payment_{0}', Locale.translate('cancelled')),
            });
          });

          handler.on(handler.EVENT_CANCEL_FAILED, () => {
            def.reject({
              error: Locale.translate('could_not_cancel_payment_on_the_pin_terminal_please_recover_the_transaction'),
            });
          });

          handler.on(handler.EVENT_FINISHED, (result) => {
            // Payment is done, so hide the abort button
            cashierDisplay.toggleCancelButton(false);
            this.handleCCVResult({
              overallResult: result.overallResult,
              def,
              handler,
              payment,
            });
          });
        }, def.reject);
      }, def.reject);
    },

    handleCCVResult({
      def,
      overallResult,
      handler,
      payment,
    }) {
      if (overallResult === handler.RESULT_SUCCESS) {
        // Verifying the payment makes sure the backend has also received
        // a "success" status for the payment.
        handler.verifyPayment(payment.id, def);
      } else {
        // Payment failed
        const errorTranslation = (status) => Locale.translate('payment_{0}', Locale.translate(status));

        if (overallResult === handler.RESULT_CANCELLED) {
          def.reject({
            error: errorTranslation('cancelled'),
          });
        } else if (overallResult === handler.RESULT_TIMEOUT) {
          def.reject({
            error: errorTranslation('expired'),
          });
        } else if (overallResult === handler.RESULT_UNAVAILABLE) {
          def.reject({
            error: errorTranslation('pin_terminal_unavailable'),
          });
        } else {
          def.reject({
            error: errorTranslation('error'),
          });
        }
      }
    },

    addCCVPaymentUnknownResultHandler({
      def,
      handler,
      cashierDisplay,
      payment,
    }) {
      handler.on(handler.EVENT_UNKNOWN_RESULT, () => {
        /* The pin terminal has probably been disconnected, so we don't
        know if the payment succeeded.
        We need to figure that out by recovering the payment.
        The payment is recovered by contacting the pin terminal and
        asking about the last transaction it has done.
         */

        if (cashierDisplay) {
          cashierDisplay.updateDisplay(
            Locale.translate('transaction_has_unknown_result').toUpperCase(),
            Locale.translate('recovering_transaction_in_3_seconds').toUpperCase(),
          );
          cashierDisplay.toggleCancelButton(false);
        }

        // Start recovery of payment
        setTimeout(() => {
          cashierDisplay.updateDisplay(
            Locale.translate('recovering_payment_dot_dot_dot').toUpperCase(),
            Locale.translate('this_may_take_a_while').toUpperCase(),
          );

          handler.startRecovery(cashierDisplay).then((overallResult) => {
            this.handleCCVResult({
              overallResult, def, handler, payment,
            });
          }, def.reject);
        }, 3000);
      });
    },

  });
  return new PaymentComponent();
});
