define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/shop.cash-register-retail/components/backgroudSync',
  'modules/upx/components/upx',
], (
  $, _, Backbone, Component,
  BGSync, Upx,
) => {
  const OrderReceiptComponent = Component.extend({

    JOB_SAVE_RECEIPT: 'order_receipt::save_receipt',
    JOB_SAVE_REFUND_RECEIPT: 'order_refund_receipt::save_receipt',

    initialize(option) {
      Component.prototype.initialize.apply(this, option);

      BGSync.registerJobType(this.JOB_SAVE_RECEIPT, this.saveOrderReceipt, this);
      BGSync.registerJobType(this.JOB_SAVE_REFUND_RECEIPT, this.saveOrderRefundReceipt, this);
    },

    async saveOrderReceiptJob(orderId, receiptEscpos) {
      return BGSync.addJob(
        this.JOB_SAVE_RECEIPT,
        [this.getOrderBackRef(orderId)],
        {
          orderId,
          receiptEscpos,
        },
      );
    },

    saveOrderReceipt(args) {
      return BGSync.awaitDeferred(Upx.call('ShopModule', 'setOrderVars', {
        fields: {
          order_id: args.orderId,
          order_vars: [{
            alias: 'receipt_escpos',
            value: args.receiptEscpos,
          }],
        },
      }));
    },

    async saveOrderRefundReceiptJob(orderId, data) {
      return BGSync.addJob(
        this.JOB_SAVE_REFUND_RECEIPT,
        [this.getOrderBackRef(orderId)],
        {
          orderId,
          data,
        },
      );
    },

    saveOrderRefundReceipt({ orderId, data }) {
      return BGSync.awaitDeferred(Upx.call('ShopModule', 'setOrderVars', {
        fields: {
          order_id: orderId,
          order_vars: [data],
        },
      }));
    },

    getOrderBackRef(orderId) {
      return `ShopModule::Order(id=${orderId})`;
    },
  });
  return new OrderReceiptComponent();
});
