define([
  'underscore',
], (
  _,
) => {
  // full list of ids: https://admin.pay.nl/data/payment_profiles
  const PROFILE_IDEAL = 10;
  const PROFILE_BANCONTANT = 436;
  const PROFILE_IN3 = 1813;
  const PROFILE_BILINK = 1672;

  const ALL_PROFILES = {
    PROFILE_IDEAL,
    PROFILE_BANCONTANT,
    PROFILE_IN3,
    PROFILE_BILINK,
  };

  const PROFILE_BRANDS = {
    [PROFILE_IDEAL]: 1,
    [PROFILE_BANCONTANT]: 2,
    [PROFILE_IN3]: 19,
    [PROFILE_BILINK]: 16,
  };

  return {
    ...ALL_PROFILES,
    ALL_PROFILES,
    PROFILE_BRANDS,

    getPaymentImageUrl(paymentProfileId, paymentProfileIssuerId) {
      if (paymentProfileId) {
        if (typeof paymentProfileId === 'string') {
          paymentProfileId = parseInt(paymentProfileId, 10);
        }
        if (paymentProfileId === PROFILE_IDEAL && paymentProfileIssuerId) {
          return `/images/payment_banks/qr-${paymentProfileIssuerId}.png`;
        }
        if (paymentProfileId in PROFILE_BRANDS) {
          return `/images/payment_profile_brands/${PROFILE_BRANDS[paymentProfileId]}.png`;
        }
        return `/images/payment_profiles/${paymentProfileId}.png`;
      }
      return null;
    },
    getQrPaymentMethod(paymentProfileId) {
      if (paymentProfileId) {
        if (typeof paymentProfileId === 'string') {
          paymentProfileId = parseInt(paymentProfileId, 10);
        }
        if (paymentProfileId === PROFILE_IDEAL) {
          return 'iDEAL';
        }
        if (paymentProfileId === PROFILE_BANCONTANT) {
          return 'Bancontact';
        }
        if (paymentProfileId === PROFILE_IN3) {
          return 'In3';
        }
        if (paymentProfileId === PROFILE_BILINK) {
          return 'Bilink';
        }
      }
      return null;
    },
  };
});
