define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
  'modules/common/components/currency',

  'modules/shop.cash-register-retail/collections/paymentMethodItem',
  'modules/shop.cash-register-retail/collections/upx/PaymentProviderMethod',

  'upx.modules/ShopModule/collections/OrderPayment',
  'upx.modules/BillingModule/collections/InvoicePayment',
  'upx.modules/PaymentModule/models/Payment',
], (
  $, _, Backbone, Component, Currency,
  PaymentMethodItemCollection, PaymentProviderMethodCollection,
  OrderPaymentCollection, InvoicePaymentCollection, PaymentModel,
) => {
  const PaymentMethodItemComponent = Component.extend({

    getPaymentMethodType(providerMethodId, index) {
      let method = false;
      const paymentCollection = new PaymentMethodItemCollection();
      const cashIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.CASH_ALIAS,
      );
      const terminalIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.TERMINALDEVICE_ALIAS,
      );
      const giftcardIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.GIFTCARD_ALIAS,
      );
      const externalGiftcardIds = PaymentProviderMethodCollection
        .getAllProviderMethodIdsByMethodTypeAlias(
          PaymentProviderMethodCollection.EXTERNAL_GIFTCARD_ALIAS,
        );
      const vvvIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.VVVBON_ALIAS,
      );
      const invoiceIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.ONINVOICE_ALIAS,
      );
      const orderIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.ONORDER_ALIAS,
      );
      const otherIds = PaymentProviderMethodCollection.getAllProviderMethodIdsByMethodTypeAlias(
        PaymentProviderMethodCollection.OTHER_ALIAS,
      );

      // getting the method
      // Cash
      if (cashIds.indexOf(providerMethodId) !== -1) {
        method = paymentCollection.CASH_METHOD;
      } else
      // Pin
      if (terminalIds.indexOf(providerMethodId) !== -1) {
        method = paymentCollection.PIN_METHOD;
      } else
      // Giftcard
      if (giftcardIds.indexOf(providerMethodId) !== -1) {
        // Adding index to the method because there can be mutiple giftcards in an order.
        method = `${paymentCollection.GIFTCARD_METHOD}::${index}`;
      } else
      // External Giftcard
      if (externalGiftcardIds.indexOf(providerMethodId) !== -1) {
        // Adding index to the method because there can be mutiple giftcards in an order.
        method = `${paymentCollection.EXTERNAL_GIFTCARD_METHOD}::${index}`;
      } else
      // VVV
      if (vvvIds.indexOf(providerMethodId) !== -1) {
        method = paymentCollection.VVV_METHOD;
      } else
      // Invoice
      if (invoiceIds.indexOf(providerMethodId) !== -1) {
        method = paymentCollection.INVOICE_METHOD;
      } else
      // Order
      if (orderIds.indexOf(providerMethodId) !== -1) {
        method = paymentCollection.PAYLATER_METHOD;
      } else
      // Other
      if (otherIds.indexOf(providerMethodId) !== -1) {
        method = paymentCollection.OTHER_METHOD;
      }

      return method;
    },

    getPinReceiptFromPaymentId(paymentId) {
      const def = $.Deferred();

      const paymentModel = new PaymentModel({ id: paymentId });
      paymentModel.fetch().then((payment) => {
        let receipt;
        if (payment.payment_vars) {
          payment.payment_vars.forEach((paymentVar) => {
            if (paymentVar.name === 'receipt') {
              receipt = paymentVar.value;
            }
          });
        }
        def.resolve(receipt);
      }, () => {
        def.resolve(null);
      });

      return def;
    },

    hasPinPaymentWithMethodId(providerMethodId, index) {
      const paymentCollection = new PaymentMethodItemCollection();
      const method = this.getPaymentMethodType(providerMethodId, index);
      return method && method.startsWith(paymentCollection.PIN_METHOD);
    },

    getPinOrderPayments(orderPaymentCollection) {
      return orderPaymentCollection.filter((model, index) => this.hasPinPaymentWithMethodId(model.get('payment.provider_method_id'), index));
    },

    getPinInvoicePayments(invoicePaymentCollection) {
      return invoicePaymentCollection.filter((model, index) => this.hasPinPaymentWithMethodId(model.get('payment.provider_method.id'), index));
    },

    addMethodsForPrinting({
      index,
      paymentCollection,
      providerMethodId,
      paymentAmount,
      paymentMethod = null,
      pinReceipt = null,
    }) {
      const method = this.getPaymentMethodType(providerMethodId, index);
      if (method) {
        const data = {
          ppu_wt: Currency.toCurrency(paymentAmount),
        };
        if (
          method.startsWith(paymentCollection.EXTERNAL_GIFTCARD_METHOD)
          && paymentMethod != null
        ) {
          data.title = paymentMethod;
        } else if (method.startsWith(paymentCollection.PIN_METHOD) && pinReceipt != null) {
          data.pinReceipt = pinReceipt;
        }
        paymentCollection.addMethodForPrinting(method, data);
      }
    },

    getPaymentCollectionFromOrder(orderModel) {
      const def = new $.Deferred();
      const orderPaymentCollection = new OrderPaymentCollection();
      const filters = [{
        name: 'order_id__=',
        val: orderModel.get('id'),
      }, {
        name: 'payment/is_paid__=',
        val: '1',
      }];

      if (orderModel.get('was_refunded')) {
        filters.push({
          name: 'payment_id__in_list',
          multi_val: orderModel.get('last_refund_payment_ids'),
        });
      }

      const params = {
        params: {
          start: 0,
          limit: 0,
          filters,
        },
      };

      const paymentCollection = new PaymentMethodItemCollection();
      $.when(
        orderPaymentCollection.fetch(params),
        PaymentProviderMethodCollection.load(),
      ).then(() => {
        const pinOrderPayments = this.getPinOrderPayments(orderPaymentCollection);

        if (pinOrderPayments.length > 0) {
          return this.getPinReceiptFromPaymentId(pinOrderPayments[0].get('payment_id'));
        }

        return new $.Deferred().resolve();
      }).then((pinReceipt) => {
        orderPaymentCollection.each((model, index) => this.addMethodsForPrinting({
          index,
          paymentCollection,
          providerMethodId: model.get('payment.provider_method_id'),
          paymentMethod: model.get('payment.method'),
          paymentAmount: model.get('payment.amount'),
          pinReceipt,
        }));

        def.resolve(paymentCollection);
      }, def.reject);
      return def;
    },

    getPaymentCollectionFromInvoice(invoiceModel) {
      const def = new $.Deferred();
      const invoicePaymentCollection = new InvoicePaymentCollection();
      const params = {
        params: {
          start: 0,
          limit: 0,
          filters: [{
            name: 'invoice/id__=',
            val: invoiceModel.get('id'),
          }, {
            name: 'payment/is_paid__=',
            val: '1',
          }],
        },
      };

      const paymentCollection = new PaymentMethodItemCollection();
      $.when(
        invoicePaymentCollection.fetch(params),
        PaymentProviderMethodCollection.load(),
      ).then(() => {
        const pinInvoicePayments = this.getPinInvoicePayments(invoicePaymentCollection);

        if (pinInvoicePayments.length > 0) {
          return this.getPinReceiptFromPaymentId(pinInvoicePayments[0].get('payment_id'));
        }

        return new $.Deferred().resolve();
      }).then((pinReceipt) => {
        invoicePaymentCollection.each((model, index) => this.addMethodsForPrinting({
          index,
          paymentCollection,
          providerMethodId: model.get('payment.provider_method.id'),
          paymentMethod: model.get('payment.method'),
          paymentAmount: model.get('payment.amount'),
          pinReceipt,
        }));

        def.resolve(paymentCollection);
      }, def.reject);
      return def;
    },

  });

  return new PaymentMethodItemComponent();
});
