define([
  'jquery',
  'underscore',
  'modules/common/components/component',
  'modules/shop.common/components/mode',
  'modules/shop.cash-register-retail/components/failedCashRegisterApiLog',

], (
  $, _, Component, Mode, FailedComponent,
) => {
  const Comp = Component.extend({

    API_URI: 'cashregister.local',
    API_PORT: '8000',
    API_SSL: true,

    getApiUrl() {
      const protocol = this.API_SSL ? 'https://' : 'http://';
      return `${protocol + this.API_URI}:${this.API_PORT}`;
    },

    getWebsocketUrl() {
      const protocol = this.API_SSL ? 'wss://' : 'ws://';
      return `${protocol + this.API_URI}:${this.API_PORT}/`;
    },

    isApiAvailable() {
      return !(Mode.isInAppMode() || Mode.isInWebMode() || Mode.isInElectronMode());
    },

    call(route, method, data, options) {
      const def = new $.Deferred();
      if (!this.isApiAvailable()) {
        const msg = `Api not available in mode: ${Mode.getMode()}`;
        console.warn(`[CashRegisterApi] ${msg}`);
        def.reject({
          error: msg,
        });
        return def.promise();
      }

      method = method || 'get';
      data = data || null;
      options = options || {};

      const baseUrl = this.getApiUrl();
      const callUrl = baseUrl + route;

      const timeout = 'timeout' in options ? options.timeout : 2000;

      let contentType = false;
      if(options && options.json) {
        contentType = 'application/json; charset=utf-8';

        data = JSON.stringify(data);
      }

      $.ajax(
        callUrl,
        {
          method: method.toUpperCase(),
          data,
          cache: false,
          contentType,
          processData: false,
          timeout,
          error(response, state) {
            def.reject({
              error: state,
            });
          },
          success(response) {
            if (response.success) {
              def.resolve(response.data);
            } else {
              // { error: "...", error_code: "..." }
              // error_code: PRINTER_NOT_IDLE <- optional
              def.reject(response);
            }
          },
        },
      );

      return def;
    },

    log(file, level, content, options, date) {
      content = content || {};
      level = level || 'LOG';
      date = date || Date();

      const data = new FormData();
      data.append('date', date); // no 'new' cos we want string
      data.append('event_type', file);
      data.append('content', JSON.stringify(content));
      data.append('level', level);
      return this.call('/log', 'post', data, options).then(
        () => {
          // we need to pass the function, because require dependencies (circular)
          FailedComponent.startFlush((file, level, content, options, date) => this.log(file, level, content, options, date));
        },
        async (error) => {
          try {
            await FailedComponent.addFailed(
              {
                file,
                level,
                content,
                options,
                date,
              },
              error,
            );
          } catch (e) {
            // failed to write not much we can do about the one
            // log as warning, to not spiral it
            const warn = this.getOriginalWarn();
            warn('Failed to write to FailedLog', e);
          }
        },
      );
    },

    logAction(action, content, options) {
      content = content || {};
      content.route = location.hash;
      return this.log('action', action, content, options)
        .fail((errorResponse) => {
          // Make sure if call to the CashRegisterApi fails
          const warn = this.getOriginalWarn();
          warn('Failed to "logAction"', action, content, options, errorResponse);
        });
    },

    getOriginalWarn() {
      return console.warnOriginal || console.warn;
    },

  });

  return new Comp();
});
