define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
  'modules/upx/components/upx',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/popups/configurableSelectionPopup',
  'modules/shop.cash-register-retail/collections/NaturalSearchShopFlatProduct',

  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/components/productCache',
], (
  $, _, Backbone, Component, Upx, Locale,
  ConfigurableSelectionPopup, NaturalSearchShopFlatProductCollection,
  MessagePopupView, ProductCache,
) => {
  const Comp = Component.extend({

    initialize(options) {
      Component.prototype.initialize.call(this, options);
      this.configurableIdsForAssigned = {};
    },

    getCollationParams(shopProductModel) {
      return {
        query: 0,
        lang: Locale.getLocale(),
        start: 0,
        limit: 50,
        filters: [
          {
            name: 'flat_product/product/attribute_set_id__notnull',
            val: true,
          }, {
            name: 'flat_product/product/type',
            val: 'configurable_assign',
          }, {
            name: 'flat_product/configurable_product_id__=',
            val: shopProductModel.get('product_id'),
          }, {
            name: 'product_price/ppu_wt__notnull',
            val: true,
          },
        ],
      };
    },

    getConfigurableProductForAssociatedId(productId) {
      const def = new $.Deferred();
      this.getConfigurableProductIdForAssociatedId(productId)
        .then(
          (configurableId) => {
            ProductCache.fetchShopProductByProductId(configurableId)
              .then(def.resolve).catch(def.reject);
          },
          def.reject,
        );
      return def.promise();
    },

    getConfigurableProductIdForAssociatedId(productId) {
      const def = new $.Deferred();
      if (productId in this.configurableIdsForAssigned) {
        def.resolve(this.configurableIdsForAssigned[productId]);
      } else {
        Upx.call('ProductsModule', 'getConfigurableAssociatedProduct',
          {
            product_id: productId,
          })
          .then(
            ({ configurable_product_id }) => {
              this.configurableIdsForAssigned[productId] = parseInt(configurable_product_id, 10);
              def.resolve(configurable_product_id);
            },
            def.reject,
          );
      }
      return def.promise();
    },

    showConfigurablePopup(shopProductModel) {
      const def = new $.Deferred();
      const collection = new NaturalSearchShopFlatProductCollection();
      const params = this.getCollationParams(shopProductModel);
      $.when(
        Upx.call('ProductsModule', 'getConfigurableProduct',
          {
            product_id: shopProductModel.get('product_id'),
          }),
        collection.fetch({ params }),
      )
        .then(
          ({
            configurable_product_kind,
          }) => {
            if (collection.length === 1) {
              def.resolve(collection.first());
            } else if (collection.length === 0) {
              const view = new MessagePopupView();
              view.open(Locale.translate(
                "product_title_has_no_variations",
                shopProductModel.get('flat_product'),
              ));
              def.reject(new Error(`Product '${shopProductModel.get('flat_product')}' has no variations`));
            } else {
              const view = new ConfigurableSelectionPopup({
                model: shopProductModel,
              });
              view.open(configurable_product_kind, collection, def);
            }
          },
          def.reject,
        );

      return def;
    },

  });

  return new Comp();
});
