define([
  'jquery',

  'modules/common/components/component',
  'modules/shop.cash-register-retail/components/feature',
  'modules/common/components/currency',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
], (
  $,
  Component, Feature, Currency,
  SelectedCustomer, LoyaltyProgramModel,
) => {
  const LoyaltyProgramComponent = Component.extend({
    getLoyaltyPaymentData(
      orderOrInvoiceModel,
      loyaltyProgramPointsPaymentModel,
      loyaltyProgramModel = null,
      selectedCustomerModel = null,
      shouldGainPoints = true,
    ) {
      if (Feature.isLoyaltyFeatureEnabled()) {
        if (!loyaltyProgramModel) {
          loyaltyProgramModel = LoyaltyProgramModel;
        }

        if (!selectedCustomerModel) {
          selectedCustomerModel = SelectedCustomer;
        }

        // Return null if no customer is selected
        if (!selectedCustomerModel.has('id')) {
          return null;
        }

        const totalOrderPrice = orderOrInvoiceModel.get('value_wt');

        let loyaltyPointsGained = 0;
        let loyaltyPointsSpent = 0;
        let loyaltyPointsOriginalBalance = 0;
        let loyaltyPointsDeductedBalance = 0;
        let loyaltyPointsNewBalance = 0;
        let loyaltyPointsExchangeValue = 0;

        if (shouldGainPoints) {
          loyaltyPointsGained = loyaltyProgramModel.getGainedPointsValueOfFloat(
            parseFloat(totalOrderPrice),
          );
        }

        if (loyaltyProgramPointsPaymentModel) {
          loyaltyPointsSpent = parseInt(
            loyaltyProgramPointsPaymentModel.get('loyaltyPointsSpent'),
            10,
          ) || 0;
          loyaltyPointsOriginalBalance = parseInt(
            loyaltyProgramPointsPaymentModel.get('loyaltyPointsOriginalBalance'),
            10,
          ) || 0;
          loyaltyPointsDeductedBalance = loyaltyPointsOriginalBalance - loyaltyPointsSpent;
        } else {
          loyaltyPointsOriginalBalance = parseInt(
            selectedCustomerModel.get('loyalty_customer.point_total'),
            10,
          ) || 0;
          loyaltyPointsDeductedBalance = loyaltyPointsOriginalBalance;
        }

        loyaltyPointsNewBalance = loyaltyPointsDeductedBalance + loyaltyPointsGained;
        loyaltyPointsExchangeValue = loyaltyProgramModel.getSpentCurrencyValueOfPoints(
          loyaltyPointsNewBalance,
        );

        return {
          loyaltyPointsOriginalBalance,
          loyaltyPointsNewBalance,
          loyaltyPointsSpent,
          loyaltyPointsGained,
          loyaltyPointsExchangeValue,
        };
      }

      return null;
    },
  });

  return new LoyaltyProgramComponent();
});
