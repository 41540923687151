define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',

  'upx.modules/ProductsModule/collections/TranslatedCategory',
  'upx.modules/ShopModule/collections/Shop',
], (
  $, _, Backbone,
  Locale, Toaster,
  TranslatedCategoryCollection, ShopCollection,
) => ({
  category_cache: [],

  is_loaded: false,

  category_limit: 250,

  last_error: false,

  load() {
    const def = new $.Deferred();

    if (this.is_loaded) {
      def.resolve(this);
    } else {
      const shopCollection = new ShopCollection();
      const shopParameters = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'id',
          dir: 'asc',
        }],
      };

      shopCollection.fetch({ params: shopParameters })
        .then(() => {
          const shop_category_ids = [];
          shopCollection.each((shopModel) => {
            const categoryId = shopModel.get('category_id');
            // check if number and is not already added to list.
            if (_.isNumber(categoryId) && shop_category_ids.indexOf(categoryId) === -1) {
              shop_category_ids.push(categoryId);
            }
          });

          const category_collection = new TranslatedCategoryCollection();

          const category_parameters = this.categoryFetchParameters(shop_category_ids);

          $.when(
            category_collection.fetch({ params: category_parameters }),
          ).then((category_response) => {
            const category_total = category_response.total;

            this.category_cache = category_response.data;
            this.is_loaded = true;
            def.resolve(this);
            // Check category total
            if (category_total > this.category_limit) {
              Toaster.error(Locale.translate('there_are_more_active_categories_than_the_allowed_{0}_there_are_{1}_categories_active_dot', [
                this.category_limit,
                category_total,
              ]));
            }
          }, def.reject);
        }, def.reject);
    }

    return def;
  },

  unload() {
    this.category_cache = [];
    this.is_loaded = false;
  },

  reload() {
    this.unload();
    return this.load();
  },

  categoryFetchParameters(shop_category_ids) {
    return {
      lang: Locale.getLocale(),
      start: 0,
      limit: this.category_limit,
      sort: [
        {
          name: 'order',
          dir: 'asc',
        },
        {
          name: 'title',
          dir: 'asc',
        },
      ],
      filters: [{
        name: 'published__=',
        val: '1',
      }, {
        name: 'category_tree/level__=',
        val: '1',
      }, {
        name: 'category_tree/path__overlap',
        multi_val: shop_category_ids,
      }],
    };
  },

  getCategoryCollection() {
    return new TranslatedCategoryCollection(this.category_cache);
  },
}));
