define([], () => ({

  isDevelopment() {
    return location.hostname === 'localhost';
  },

  isProduction() {
    return !this.isDevelopment();
  },

  isCustomerScreen() {
    return location.hash === '#customer-screen';
  },

}));
