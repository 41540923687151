define([
  'underscore',

  'modules/common/components/component',
  'dexie',
  'modules/common/crontabs/cron',
  'modules/common/components/uuid',
  'modules/shop.cash-register-retail/components/timer',
  '@storekeeper/sentry',
  'modules/upx/collections/users',
], (
  _,
  Component, Dexie, Cron, Uuid, Timer, SKSentry, UserCollection,
) => {
  const secondDelay = 1000;
  const minuteDelay = 60 * secondDelay;
  const dayDelay = minuteDelay * 60 * 24;
  const now = () => new Date().getTime();

  const debug = (msg, context) => console.debug(`[backgroundSync] ${msg}`, context);

  const c = Component.extend({
    EVENT_SUCCESS: 'success',
    EVENT_FAILED: 'failed',

    initialize() {
      this.db = new Dexie('backgroundSync');
      this.flushing = false;
      this.db.version(3).stores({
        jobs: 'id, type, *backRefs',
        schedules: '[time+jobId],time,jobId',
      });

      const cronClass = Cron.extend({
        cron: '*/5 * * * * *', // 5s
        run: () => {
          this.tryFlush();
        },
      });
      this.cron = new cronClass();
      if (location.hash !== '#customer-screen') {
        this.cron.start();
      }
      this.jobTypes = {};
      this.jobCallbacks = [];
    },

    registerJobType(type, callback, context) {
      if (type in this.jobTypes) {
        throw new Error('Job cannot be registered twice');
      }
      if (!_.isFunction(callback)) {
        throw new Error('callback param need to be callback');
      }
      let call = callback;
      if (context) {
        call = _.bind(callback, context);
      }
      this.jobTypes[type] = call;
    },

    async hasJobsForObject(backRef) {
      return await this.db.jobs
        .where('backRefs').equals(backRef)
        .count() > 0;
    },

    async getJobsForObject(backRef) {
      return await this.db.jobs
        .where('backRefs').equals(backRef)
        .toArray();
    },

    async addJobSchedule(id, time) {
      time = time || now();
      await this.db.schedules.put({
        time,
        jobId: id,
      });
      debug('Scheduled job', {
        jobId: id,
        time,
        date: new Date(time),
      });
    },
    /**
         * retry times from last failure (in mins): 1,2,4,8, ...,512, 1440
         * first 24h it will retry 9 times, after once every 24h
         * @param {number} retryNo
         * @returns {number}
         */
    getJobRetryDelay(retryNo) {
      let delay = minuteDelay;
      if (retryNo > 0) {
        if (retryNo <= 9) {
          delay = minuteDelay * Math.pow(2, retryNo);
        } else {
          delay = dayDelay;
        }
      }
      return delay;
    },
    /**
         * reschedule the job in case of failure
         * @param job
         * @param {Error} e
         * @returns {Promise<void>}
         */
    async addRetryJobSchedule(job, e) {
      const retryNo = job.retryNo || 0;
      const delay = this.getJobRetryDelay(retryNo);
      const time = now();
      await this.db.jobs.update(job.id, {
        retryNo: retryNo + 1,
        lastRetryTime: time,
      });
      await this.addJobSchedule(job.id, time + delay);

      if (retryNo >= 10) {
        // notify sentry something is really broken
        SKSentry.Sentry.withScope((scope) => {
          scope.setTag('background_sync_type', job.type);
          scope.setExtra('background_sync_job', job);

          // if `e` is not an error
          if (!this.isError(e)) {
            // check if `error` is in `e` and create an error with that message
            if (_.isObject(e) && 'error' in e) {
              e = new Error(e.error);
            }
            // else we JSON.stringify `e` to make sure the error is passed to sentry
            else {
              e = new Error(JSON.stringify(e));
            }
          }

          SKSentry.Sentry.captureException(e);
        });
      }
    },

    /**
         * take from underscore source: https://github.com/jashkenas/underscore/blob/master/underscore.js#L1326
         * We have an old version which does not have this function
         * @param obj
         * @return {boolean}
         */
    isError(obj) {
      return toString.call(obj) === '[object Error]';
    },

    errorToJSON(error) {
      const alt = {};
      Object.getOwnPropertyNames(error).forEach((key) => {
        alt[key] = error[key];
      });
      return alt;
    },
    /**
         * added new job and schedules the first execution
         * @param {string} type
         * @param {array<string>} backRefs
         * @param {array} args
         * @returns {Promise<number>}
         */
    async addJob(type, backRefs, args) {
      backRefs = backRefs || [];
      args = args || {};
      if (!(_.isObject(args) && !_.isFunction(args) && !_.isArray(args))) {
        throw new Error('Args param need to be plain object');
      }
      // convert any possible inside objects to simple ones
      args = JSON.parse(JSON.stringify(args));

      if (!_.isArray(backRefs)) {
        throw new Error('backRefs param need to be array');
      }
      const id = Uuid.genRandom();
      const time = now();
      const putData = {
        id,
        time,
        type,
        args,
        backRefs,
      };
      await this.db.jobs.put(putData);
      debug('Added new job to sync', putData);

      await this.addJobSchedule(id);
      this.tryFlush(); // to not await for the result
      return id;
    },

    /**
     * Adds a new job and awaits it.
     * Note: The promise is rejected when the job fails for the FIRST time.
     *       Every time it fails after will be ignored.
     * @param type
     * @param backRefs
     * @param args
     * @returns {Promise<unknown>}
     */
    async addAndAwaitJob(type, backRefs, args) {
      const jobId = await this.addJob(type, backRefs, args);

      return new Promise((resolve, reject) => {
        this.on(jobId, this.EVENT_SUCCESS, () => {
          resolve();
        }, true);
        this.on(jobId, this.EVENT_FAILED, (e) => {
          reject(e);
        }, true);
      });
    },

    awaitDeferred(def) {
      return new Promise(async (resolve, reject) => {
        try {
          if (_.isObject(def) && _.isFunction(def.then)) {
            // it`s a promise
            def.then(resolve, reject);
          } else {
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      });
    },

    async runJob(job) {
      const typeName = job.type;
      if (!(typeName in this.jobTypes)) {
        throw new Error(`Unknown job type: ${typeName}`);
      }
      const timer = Timer.createTimer(`backgroundSync-${typeName}`, job);
      try {
        const callback = this.jobTypes[typeName];
        await callback(job.args);
        this.handleCallbackEvent(job.id, this.EVENT_SUCCESS);
        timer.resolve();
      } catch (e) {
        this.handleCallbackEvent(job.id, this.EVENT_FAILED, e);
        timer.reject(e);
        await this.db.jobs.update(job.id, {
          lastError: this.errorToJSON(e),
        });
        throw e;
      }
      debug('Job success', { jobId: job.id });

      await this.deleteJob(job.id); // success -> remove it
    },

    async deleteJob(jobId) {
      await this.db.jobs.delete(jobId);
      await this.db.schedules
        .where('jobId').equals(jobId)
        .delete();

      // Remove all callbacks of the job
      this.jobCallbacks = this.jobCallbacks.filter((jobCallback) => jobCallback.jobId === jobId);

      debug('Job deleted', { jobId });
    },

    async deleteJobSchedule(jobSchedule) {
      await this.db.schedules
        .where(['time', 'jobId']).equals([jobSchedule.time, jobSchedule.jobId])
        .delete();
      debug('JobSchedule deleted', jobSchedule);
    },

    async tryFlush(force) {
      if (!this.flushing || force) {
        this.flushing = true;
        try {
          const jobSchedule = await this.db.schedules
            .where('time').belowOrEqual(now())
            .first();
          if (jobSchedule) {
            if (UserCollection.hasActiveUser()) {
              const { jobId } = jobSchedule;
              // delete the row, it will be readded if fails
              debug('Running job', jobSchedule);

              const job = await this.db.jobs.get(jobId);
              await this.deleteJobSchedule(jobSchedule);
              try {
                if (job) {
                  await this.runJob(job);
                } else {
                  console.error('Job cannot be found', { jobId });
                }

                // process next one -> no need to wait for the result
                // setTimeout to make sure executed after stack is empty
                setTimeout(() => this.tryFlush());
              } catch (e) {
                debug('Job failed', {
                  jobSchedule,
                  e,
                });
                await this.addRetryJobSchedule(job, e);
              }
            } else {
              debug('Job skipped, no active user', jobSchedule);
            }
          }
        } finally {
          this.flushing = false; // this one is executed BEFORE the tryFlush above
        }
      }
    },

    on(jobId, event, callback, once = false) {
      this.jobCallbacks.push({
        jobId,
        event,
        callback,
        once,
      });
    },

    handleCallbackEvent(jobId, event, args = null) {
      const indicesToRemove = [];
      for (let i = 0; i < this.jobCallbacks.length; i++) {
        const jobCallback = this.jobCallbacks[i];

        if (jobCallback.jobId === jobId && jobCallback.event === event) {
          jobCallback.callback(args);
          if (jobCallback.once) {
            // Callback should only be executed once, so it should be removed.
            indicesToRemove.push(i);
          }
        }
      }

      for (const index of indicesToRemove) {
        this.jobCallbacks.slice(index, 1);
      }
    },
  });

  return new c();
});
