define([
  'jquery',
  'upx.modules/RelationsModule/collections/FullInfoRelation',
  'upx.modules/ShopModule/collections/MemberCard',
  'upx.modules/ShopModule/collections/CustomerPermanentDiscount',

  'modules/common/components/locale',
], (
  $, FullInfoRelationCollection, MemberCardCollection, CustomerPermanentDiscountCollection,
  Locale,
) => ({
  getFullInformation({ relation_data_id }) {
    const def = new $.Deferred();

    const collection = new FullInfoRelationCollection();
    const params = {
      start: 0,
      limit: 1,
      filters: [{
        name: 'id__=',
        val: relation_data_id,
      }],
    };

    collection.fetch({ params })
      .then(() => {
        const model = collection.first();
        if (model) {
          def.resolve(model);
        } else {
          def.reject({
            error: Locale.translate('customer_not_found'),
          });
        }
      }, def.reject);

    return def;
  },

  getPermanentDiscount({ relation_data_id }) {
    const def = new $.Deferred();
    const discountCollection = new CustomerPermanentDiscountCollection();
    const discountParams = {
      start: 0,
      limit: 1,
      filters: [{
        name: 'relation_data_id__=',
        val: relation_data_id,
      }],
    };
    discountCollection.fetch({ params: discountParams }).always(
      () => {
        let permanentDiscount = 0;
        if (discountCollection.length) {
          permanentDiscount = discountCollection.first().get('percentage');
        }
        def.resolve(permanentDiscount);
      },
    );
    return def.promise();
  },
  getMemberCard({ relation_data_id }) {
    const def = new $.Deferred();

    this.listMemberCards({ relation_data_id })
      .then((collection) => {
        if (collection.length === 1) {
          const model = collection.first();
          def.resolve({ model });
        } if (collection.length > 1) {
          def.resolve({ collection });
        } else {
          def.resolve();
        }
      }, def.reject);

    return def;
  },

  listMemberCards({ relation_data_id }) {
    const def = new $.Deferred();

    const collection = new MemberCardCollection();
    const params = {
      start: 0,
      limit: 0,
      sort: [{
        name: 'due_date_inclusive',
        dir: 'desc',
      }],
      filters: [{
        name: 'relation_data_id__=',
        val: relation_data_id,
      }, {
        name: 'active__=',
        val: true,
      }],
    };

    collection.fetch({ params })
      .then(() => def.resolve(collection), def.reject);

    return def;
  },
}));
