define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/common/components/locale',

  'pickadate/lib/picker',
  'pickadate/lib/picker.date',
  'pickadate/lib/picker.time',

  'pickadate/lib/themes/default.css',
  'pickadate/lib/themes/default.date.css',
  'pickadate/lib/themes/default.time.css',
], (
  $, _, Backbone, Component,
  Locale,
) => {
  const DateTimePicker = Component.extend({

    datePicker(options) {
      const def = new $.Deferred();

      options = _.extend({}, this.getDateDefaults(def), options);

      // create input
      const $input = $('<input>');
      if ('value' in options) $input.val(options.value);

      //  spawn picker
      $input.pickadate(options);
      $input.click(); // open picker

      return def;
    },

    timePicker(options) {
      const def = new $.Deferred();

      options = _.extend({}, this.getTimeDefaults(def), options);

      // create input
      const $input = $('<input>');
      if ('value' in options) $input.val(options.value);

      //  spawn picker
      $input.pickatime(options);
      $input.click(); // open picker

      return def;
    },
    getDateDefaults(def) {
      return {
        monthsFull: [
          Locale.translate('january'),
          Locale.translate('february'),
          Locale.translate('march'),
          Locale.translate('april'),
          Locale.translate('may'),
          Locale.translate('june'),
          Locale.translate('july'),
          Locale.translate('august'),
          Locale.translate('september'),
          Locale.translate('october'),
          Locale.translate('november'),
          Locale.translate('december'),
        ],
        monthsShort: [
          Locale.translate('jan'),
          Locale.translate('feb'),
          Locale.translate('mar'),
          Locale.translate('apr'),
          Locale.translate('may'),
          Locale.translate('jun'),
          Locale.translate('jul'),
          Locale.translate('aug'),
          Locale.translate('sep'),
          Locale.translate('oct'),
          Locale.translate('nov'),
          Locale.translate('dec'),
        ],
        weekdaysFull: [
          Locale.translate('sunday'),
          Locale.translate('monday'),
          Locale.translate('tuesday'),
          Locale.translate('wednesday'),
          Locale.translate('thursday'),
          Locale.translate('friday'),
          Locale.translate('saturday'),
        ],
        weekdaysShort: [
          Locale.translate('su'),
          Locale.translate('mo'),
          Locale.translate('tu'),
          Locale.translate('we'),
          Locale.translate('th'),
          Locale.translate('fr'),
          Locale.translate('sa'),
        ],
        today: Locale.translate('today'),
        clear: false, // Set name if you want to allow unsetting
        close: Locale.translate('close'),
        firstDay: 1,
        format: 'yyyy-mm-dd',
        formatSubmit: 'yyyy-mm-dd',
        container: 'body',
        onClose() {
          const value = this.get('select', 'yyyy-mm-dd');
          if (value === '') {
            def.reject();
          } else {
            const split = value.split('-');
            def.resolve({
              value,
              year: parseInt(split[0]),
              month: parseInt(split[1]),
              day: parseInt(split[2]),
            });
          }
          this.stop();
        },
      };
    },

    getTimeDefaults(def) {
      return {
        clear: false, // Set name if you want to allow unsetting
        format: 'HH:i',
        formatSubmit: 'HH:i',
        hiddenName: true,
        container: 'body',
        interval: 15,
        onClose() {
          const value = this.get('value'); // Returns date when clicking beside it.
          if (value === '') {
            def.reject();
          } else {
            const split = value.split(':');
            // For some reason when you click beside it. it triggeres with a date.
            if (split.length === 2) {
              def.resolve({
                value,
                hour: parseInt(split[0]),
                minute: parseInt(split[1]),
              });
            } else {
              def.reject();
            }
          }
          this.stop();
        },
      };
    },

  });

  return new DateTimePicker();
});
