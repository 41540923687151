define([
  'application',
  'jquery',
  'modules/common/components/component',
  'modules/common/components/locale',
  'modules/common/components/managers/menu',
], (App, $, Component, Locale, MenuManager) => {
  const Menu = Component.extend({

    unload() {
      const menuItems = App.com(MenuManager).get('main');
      menuItems.reset(); // Unloads all menu items
    },

    load() {
      const def = $.Deferred();
      const menu = [];

      const menuItems = App.com(MenuManager).get('main');
      menuItems.reset();

      menu.push({
        alias: 'checkout',
        icon: 'fas fa-shopping-cart',
        name: Locale.translate('checkout'),
        route: 'checkout',
        order: 0,
        module: 'shop.cash-register-retail',
      });

      menu.push({
        alias: 'payment',
        icon: 'fas fa-shopping-cart',
        name: Locale.translate('payment'),
        route: 'payment',
        order: 0,
        module: 'shop.cash-register-retail',
      });

      menuItems.add(menu);

      def.resolve();

      return def.promise();
    },
  });

  return new Menu();
});
