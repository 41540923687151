define([], (
) => ({
  cleanEmptyEmail(email) {
    email = email || '';
    email = email.trim();
    if (email !== '' && !email.startsWith('nomail+')) {
      return email;
    }
    return null;
  },
}));
