define([
  'underscore',
  'modules/common/components/component',
  'dexie',
],
(_, Component, Dexie) => {
  const maxAgeInS = 24 * 60 * 60; // default max age in seconds (24 hours)
  const statisticsDefaultAgeInS = 30 * 60; // default max age for statistics in seconds (30 minutes)

  const comp = Component.extend({

    name: 'modules/shop.cash-register-retail/components/networkDisruptionStorage',

    initialize(options) {
      options = options || {};

      this.db = new Dexie('networkDisruption');
      this.db.version(1).stores({
        checks: '++id,&time, success',
      });
    },

    async write(success, timestamp) {
      // Insert the item in the database
      const currentTimestamp = new Date().getTime();
      timestamp = timestamp || currentTimestamp;

      // Remove items which are older then the maximum age
      const purgeBefore = currentTimestamp - (maxAgeInS * 1000);
      await this.removeOldItems(purgeBefore);

      // write the new row
      await this.db.checks.put({
        time: timestamp,
        success: !!success,
      });
    },

    async getStatistics(maxAge) {
      maxAge = maxAge || statisticsDefaultAgeInS;
      const currentTimestamp = new Date().getTime();
      const fromTimestamp = currentTimestamp - (maxAge * 1000);
      const collection = await this.db.checks
        .where('time').above(fromTimestamp).sortBy('time');

      let firstTimestamp = null;
      let previousTimestamp = fromTimestamp;
      let successDuration = 0;
      let failDuration = 0;
      let lastEntry;
      let lastSuccess = true;
      let failCount = 0;
      let lastTimestamp = previousTimestamp; // to catch nothing found

      for (const i in collection) {
        const item = collection[i];

        if (!firstTimestamp) {
          // first entry nothing to count
          firstTimestamp = item.time;
        }

        // calculate from 2nd entry
        lastEntry = item;
        lastTimestamp = lastEntry.time;
        const duration = lastTimestamp - previousTimestamp;
        if (lastSuccess) {
          successDuration += duration;
        } else {
          failDuration += duration;
        }
        lastSuccess = lastEntry.success;
        if (!lastSuccess) {
          failCount++;
        }
        previousTimestamp = lastTimestamp;
      }

      // calculate from last entry or no entry
      const lastDuration = currentTimestamp - lastTimestamp;
      if (lastSuccess) {
        // nothing found is treated as
        successDuration += lastDuration;
      } else {
        failDuration += lastDuration;
      }

      const totalDuration = successDuration + failDuration;
      const statistics = {
        firstEntryTimestamp: firstTimestamp,
        lastEntryTimestamp: lastTimestamp,
        secondsTotal: totalDuration / 1000,
        secondsFailed: failDuration / 1000,
        secondsSuccess: successDuration / 1000,
        failCount,
      };
      statistics.percentageSuccess = (statistics.secondsSuccess / statistics.secondsTotal) * 100;
      statistics.percentageFailed = (statistics.secondsFailed / statistics.secondsTotal) * 100;
      return statistics;
    },

    removeOldItems(fromTimestamp) {
      return this.db.checks
        .where('time').below(fromTimestamp)
        .delete();
    },
  });

  const Obj = new comp();
  window.UpxNetworkDisruptionStorage = Obj;
  return Obj;
});
