define([
  'modules/common/collections/countries',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/profile/models/profile',
  'modules/common/components/locale',
], (
  Countries, SelectedCustomerModel, ProfileModel, Locale,
) => ({

  getIcpErrors(customer = null) {
    customer = SelectedCustomerModel || SelectedCustomerModel;

    const customerCountry = this.getCountryIso2(customer);
    const accountCountry = ProfileModel.getCountryIso2();

    const errors = [];
    if (!Countries.isEuCountry(customerCountry)) {
      errors.push(Locale.translate('customer_country_{country}_is_not_within_european_union', {
        country: Countries.getTranslatedName(customerCountry),
      }));
    }
    if (!Countries.isEuCountry(accountCountry)) {
      errors.push(Locale.translate('account_country_{country}_is_not_within_european_union', {
        country: Countries.getTranslatedName(customerCountry),
      }));
    }
    if (accountCountry === customerCountry) {
      errors.push(Locale.translate('customer_must_be_from_outside_{country}', {
        country: Countries.getTranslatedName(customerCountry),
      }));
    }
    if (customer.get('is_private')) {
      errors.push(Locale.translate('customer_must_be_business', {
        country: Countries.getTranslatedName(customerCountry),
      }));
    }
    if (!customer.get('business_data.vat_number')) {
      errors.push(Locale.translate('customer_must_have_vat_number_set', {
        country: Countries.getTranslatedName(customerCountry),
      }));
    }
    if (!ProfileModel.get('relation_data.business_data.vat_number')) {
      errors.push(Locale.translate('account_must_have_vat_number_set', {
        country: Countries.getTranslatedName(customerCountry),
      }));
    }
    return errors;
  },

  getCountryIso2(customer) {
    return customer.get('contact_address.country_iso2')
        || customer.get('address_billing.country_iso2')
        || customer.get('business_data.country_iso2');
  },
}));
